import React from "react";

import * as SC from "./style";

const GranichFigmaLessonDuration = () => (
  <SC.StyledSection>
    <SC.StyledContainer>
      <SC.StyledWrapper>
        <SC.StyledTimerIllustration />
        <SC.SectionText>
          Общее время{" "}
          <img src="/images/emojis/_alarm.png" alt="alarm" loading="lazy" />{" "}
          видео-лекций 6 часов 6 минут. Все видео без воды{" "}
          <img
            src="/images/emojis/_forbid_water.png"
            alt="no_water"
            loading="lazy"
          />
          , с фирменной подачей Гранича{" "}
          <img
            src="/images/emojis/_pinched_fingers.png"
            alt="pinched_fingers"
            loading="lazy"
          />
        </SC.SectionText>
      </SC.StyledWrapper>
    </SC.StyledContainer>
  </SC.StyledSection>
);

export default GranichFigmaLessonDuration;
