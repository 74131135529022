// react/gatsby imports
import React from "react";
// data
import localData from "data/pages/granich-figma";
// assets
import ogImage from "assets/images/seo/granich-figma.jpg";
import ogImageVK from "assets/images/seo/vk/granich-figma.jpg";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// hooks
import useFigmaQuery from "hooks/graphql/useFigmaQuery";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import GranichFigmaOffer from "components/pages/course-page/granich-figma/offer";
import GranichFigmaWhyShouldIBuy from "components/pages/course-page/granich-figma/why-should-i-buy";
import GranichFigmaAuthors from "components/pages/course-page/granich-figma/authors";
import GranichFigmaLotsOfSlides from "components/pages/course-page/granich-figma/lots-of-slides";
import GranichFigmaUIKitExample from "components/pages/course-page/granich-figma/ui-kit-example";
import GranichFigmaLessonDuration from "components/pages/course-page/granich-figma/lesson-duration";
import GranichFigmaTableOfContents from "components/pages/course-page/granich-figma/table-of-contents";
import GranichFigmaQualitySlides from "components/pages/course-page/granich-figma/quality-slides";
import GranichFigmaFeatures from "components/pages/course-page/granich-figma/features";
import GranichFigmaWhoFits from "components/pages/course-page/granich-figma/who-fits-master-class";
import GranichFigmaReviews from "components/pages/course-page/granich-figma/reviews";
import GranichFigmaFacts from "components/pages/course-page/granich-figma/facts";
import GranichFigmaChat from "components/pages/course-page/granich-figma/chat";
import GranichFigmaPriceRange from "components/pages/course-page/granich-figma/price-options";
import GranichFigmaPaymentChoices from "components/pages/payment-choices";
import CourseQandA from "components/pages/course-page/q-and-a";
import FigmaQAndAHeader from "components/pages/course-page/granich-figma/q-and-a-header";

const GranichFigmaPage = () => {
  const prismicData = useFigmaQuery();
  const { data: seoData } = prismicData.page || {};

  return (
    <Layout>
      <div className="section-top-block" />
      <SEO
        title="Мастер-класс Granich Figma"
        description={
          seoData?.seodescription?.text ||
          "С видео-уроками от Гранича. Идёт легко как питерская шаверма. Уютно, словно вечером на кухне болтаем за дизайн."
        }
        keywords={["figma", "фигма", "гранич", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.granichFigma)}
      />
      <GranichFigmaOffer />
      <GranichFigmaWhyShouldIBuy />
      <GranichFigmaAuthors isShowOnDesktop />
      <GranichFigmaLotsOfSlides />
      <GranichFigmaUIKitExample />
      <GranichFigmaLessonDuration />
      <GranichFigmaTableOfContents />
      <GranichFigmaQualitySlides />
      <GranichFigmaFeatures />
      <GranichFigmaWhoFits />
      <GranichFigmaReviews />
      <GranichFigmaFacts />
      <GranichFigmaChat />
      <div id="participation-section" />
      <GranichFigmaPriceRange
        formId="ltForm3153227"
        priceRange={localData.priceRange}
        formPostUrl="https://school.granich.design/pl/lite/block-public/process-html?id=1871736015"
      />
      <GranichFigmaPaymentChoices courseType="Курс" />
      <GranichFigmaAuthors isShowOnMobile />
      <CourseQandA
        data={localData.figmaQandA}
        title="Частые вопросы"
        urlText="Мастер-класса!"
        customHeader={<FigmaQAndAHeader />}
      />
    </Layout>
  );
};

export default GranichFigmaPage;
