import styled from "styled-components";
import Img from "gatsby-image";
import {
  Container,
  NewSectionTitle,
  NewWrapper,
  Section,
} from "components/style";

export const StyledSection = styled(Section)`
  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledContainer = styled(Container)``;

export const StyledWrapper = styled(NewWrapper)``;

export const StyledSectionTitle = styled(NewSectionTitle)`
  line-height: 0.85;
  margin-bottom: 4vw;
  font-size: 4.5vw;
  letter-spacing: -0.09vw;
  margin-top: -0.5vw;
  max-width: 90%;

  img {
    width: 4vw;
    min-width: 4vw;
    position: relative;
    top: 0.2vw;
    left: 0.3vw;
  }
`;

export const SlidesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  .gatsby-image-wrapper {
    :nth-child(1) {
      z-index: 4;
      transform: rotate(1.5deg);
      img {
        transform: scale(1.01);
      }
    }
    :nth-child(2) {
      z-index: 5;
      transform: rotate(-3deg);
      box-shadow: 0 0 1.5vw rgba(0, 0, 0, 0.5);
      img {
        transform: scale(1.01);
      }
    }
    :nth-child(3) {
      z-index: 4;
      transform: rotate(1.5deg);
      img {
        transform: scale(1.01);
      }
    }
    :nth-child(4) {
      z-index: 3;
      transform: rotate(1.5deg);
    }
    :nth-child(5) {
      z-index: 2;
      transform: rotate(1.5deg);
    }
    :nth-child(6) {
      z-index: 1;
      transform: rotate(-1.5deg);
    }
  }
`;

export const SlideImage = styled(Img)`
  position: relative;
  box-shadow: 0 0.3vw 0.8vw rgba(0, 0, 0, 0.25);
`;
