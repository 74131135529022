import React from "react";
import * as SC from "./QandAItem";
import QandAItem from ".";
import ContentComponent from "./ContentComponent";

const QandAItemDesktop = ({ data }) => (
  <SC.AccordionWrapper>
    {data.map(
      ({ tagName, tagType, isWrap, firstColumn, secondColumn }, idx) => {
        if (!isWrap) {
          return (
            <ContentComponent
              key={idx}
              isWrap={isWrap}
              firstColumn={firstColumn}
              secondColumn={secondColumn}
            />
          );
        }
        return (
          <QandAItem
            open={idx}
            key={idx}
            tag={tagName}
            type={!!tagType}
            idx={idx}
          >
            <ContentComponent
              isWrap={isWrap}
              firstColumn={firstColumn}
              secondColumn={secondColumn}
            />
          </QandAItem>
        );
      }
    )}
  </SC.AccordionWrapper>
);

export default QandAItemDesktop;
