import React from "react";
import { Container } from "components/style";
import scrollTo from "gatsby-plugin-smoothscroll";
import "react-accessible-accordion/dist/fancy-example.css";
import useAdaptive from "hooks/useAdaptive";
import QandAItemDesktop from "./q-and-a-item/QandAItemDesktop";
import QandAItemMobile from "./q-and-a-item/QandAItemMobile";
import * as SC from "./style";

const CourseQandA = ({ data, title, description, urlText, customHeader }) => {
  const { isMobile, isDesktop } = useAdaptive();

  return (
    <SC.Section>
      <Container>
        <SC.MainWrapper>
          <SC.Wrapper>
            {customHeader ?? (
              <SC.Header>
                <SC.SectionTitle>{title}</SC.SectionTitle>
                {description && <SC.SectionText>{description}</SC.SectionText>}
              </SC.Header>
            )}
            {isDesktop && <QandAItemDesktop data={data} />}
            {isMobile && <QandAItemMobile data={data} />}
          </SC.Wrapper>
          <SC.FooterContainer>
            <SC.FooterWrapper>
              <SC.FooterLink onClick={() => scrollTo("#participation-section")}>
                Вернуться к форме оплаты {urlText}
                <SC.ArrowLinkTop />
              </SC.FooterLink>
            </SC.FooterWrapper>
            <SC.FooterWrapperMobile>
              Вернуться к форме оплаты{" "}
              <SC.FooterLink onClick={() => scrollTo("#participation-section")}>
                {urlText}
                <SC.ArrowLinkTopMobile />
              </SC.FooterLink>
            </SC.FooterWrapperMobile>
          </SC.FooterContainer>
        </SC.MainWrapper>
      </Container>
    </SC.Section>
  );
};

export default CourseQandA;
