import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as SC from "./style";

const GranichFigmaWhoFits = () => {
  const image = useStaticQuery(graphql`
    query granichFigmaWhoFitsImage {
      slide1: file(relativePath: { eq: "granich-figma/who-fits/alpines.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide2: file(relativePath: { eq: "granich-figma/who-fits/pelevin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide3: file(
        relativePath: { eq: "granich-figma/who-fits/mushroom.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide4: file(relativePath: { eq: "granich-figma/who-fits/izum.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const slide1 = image.slide1.childImageSharp.fluid;
  const slide2 = image.slide2.childImageSharp.fluid;
  const slide3 = image.slide3.childImageSharp.fluid;
  const slide4 = image.slide4.childImageSharp.fluid;

  return (
    <SC.StyledSectiom>
      <SC.StyledContainer>
        <SC.StyledWrapper>
          <SC.StyledSectionTitle>
            Кому <span>не</span> подойдёт этот МК
          </SC.StyledSectionTitle>
          <SC.SlidesScrollWrapper>
            <SC.SlidesWrapper>
              <SC.SlidesFirstWrapper>
                <SC.SlideImageWrapperClimbers>
                  <SC.SlideImage fluid={slide1} />
                  <span>Промышленным альпинистам</span>
                </SC.SlideImageWrapperClimbers>
                <SC.SlideImageWrapperPelevin>
                  <SC.SlideImage fluid={slide2} />
                  <span>Виктору Пелевину</span>
                </SC.SlideImageWrapperPelevin>
              </SC.SlidesFirstWrapper>
              <SC.SlidesSecondWrapper>
                <SC.SlideImageWrapperMushrooms>
                  <SC.SlideImage fluid={slide3} />
                  <span>Грибникам</span>
                </SC.SlideImageWrapperMushrooms>
                <SC.SlideImageWrapperRaisin $isLast>
                  <SC.SlideImage fluid={slide4} />
                  <span>Изюму</span>
                </SC.SlideImageWrapperRaisin>
              </SC.SlidesSecondWrapper>
            </SC.SlidesWrapper>
          </SC.SlidesScrollWrapper>
          <SC.WhoFitsWrapper>
            <i>Остальным</i>
            <SC.EmojisInOneRowWrapper>
              <SC.EmojiWrapper>
                <img
                  src="/images/emojis/_ninja.png"
                  loading="lazy"
                  alt="star"
                />
              </SC.EmojiWrapper>
              <SC.EmojiWrapper>
                <img
                  src="/images/emojis/_teacher.png"
                  loading="lazy"
                  alt="star"
                />
              </SC.EmojiWrapper>
              <SC.EmojiWrapper>
                <img src="/images/emojis/_cook.png" loading="lazy" alt="star" />
              </SC.EmojiWrapper>
              <SC.EmojiWrapper>
                <img src="/images/emojis/_elf.png" loading="lazy" alt="star" />
              </SC.EmojiWrapper>
              <SC.EmojiWrapper>
                <img
                  src="/images/emojis/_superwoman.png"
                  loading="lazy"
                  alt="superwoman"
                />
              </SC.EmojiWrapper>
            </SC.EmojisInOneRowWrapper>
            будет норм. Особенно если они{" "}
            <SC.UnderlinedItalic>начинающие</SC.UnderlinedItalic> UX/UI
            <SC.EmojisInOneRowWrapper>
              <SC.EmojiWrapper>
                <img
                  src="/images/emojis/_girl_computer.png"
                  loading="lazy"
                  alt="girl_computer"
                />
              </SC.EmojiWrapper>
              <SC.EmojiWrapper>
                <img src="/images/emojis/_boy.png" loading="lazy" alt="star" />
              </SC.EmojiWrapper>
              <SC.EmojiWrapper>
                <img src="/images/emojis/_man.png" loading="lazy" alt="star" />
              </SC.EmojiWrapper>
            </SC.EmojisInOneRowWrapper>
            ребята, или хотят из <i>граф</i>дизайна{" "}
            <SC.RunningImg
              src="/images/emojis/_running-girl.png"
              loading="lazy"
              alt="running-girl"
            />
            <SC.RunningImg
              src="/images/emojis/_running-boy.png"
              loading="lazy"
              alt="running-boy"
            />{" "}
            двигаться{" "}
            <span>
              в{" "}
              <SC.ArrowsWrapper>
                <SC.StyledArrowRightIcon />
                <SC.StyledArrowRightIcon />
                <SC.StyledArrowRightIcon />
              </SC.ArrowsWrapper>
              <SC.StyledWebPixelIcon />
              <SC.GameImg
                src="/images/emojis/_game.png"
                loading="lazy"
                alt="star"
              />
            </span>
          </SC.WhoFitsWrapper>
        </SC.StyledWrapper>
      </SC.StyledContainer>
    </SC.StyledSectiom>
  );
};

export default GranichFigmaWhoFits;
