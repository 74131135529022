import styled from "styled-components";
import Img from "gatsby-image";
import {
  Container,
  NewSectionTitle,
  NewWrapper,
  Section,
  SectionSubTitle,
} from "components/style";

export const StyledSectiom = styled(Section)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 10vw;
  }
`;

export const StyledContainer = styled(Container)``;

export const StyledWrapper = styled(NewWrapper)`
  @media only screen and (max-width: 575px) {
    border-radius: 6.5vw;
  }
`;

export const StyledSectionTitle = styled(NewSectionTitle)`
  margin-top: -1.5vw;
  font-size: 4.75vw;

  @media only screen and (max-width: 575px) {
    font-size: 8.1vw;
    max-width: 70%;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 4.5vw;
  }
`;

export const SectionSubtitle = styled(SectionSubTitle)`
  display: flex;
  align-items: center;
  font-size: 1.49vw;
  line-height: 1.5;

  span {
    max-width: 50%;
    color: rgba(150, 150, 150, 1);
    display: block;
    :first-child {
      margin-right: 1vw;
    }
  }
  @media only screen and (max-width: 575px) {
    font-size: 5vw;
    line-height: 1.4;
    margin-bottom: 4.5vw;
    flex-direction: column;
    align-items: start;

    span {
      max-width: 100%;
      :first-child {
        margin-right: 0;
        margin-bottom: 3vw;
      }
    }
  }
`;

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 1.5vw;

  @media only screen and (max-width: 575px) {
    border-radius: 5.5vw;
  }
`;

export const SlidesImageWrapper = styled.div`
  position: relative;

  @media only screen and (max-width: 575px) {
    width: 100vw;
    left: -11vw;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 0 11vw;
    top: 2vw;
    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      display: none;
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar-track {
      display: none;
    }
  }
`;

export const SlidesImage = styled(Img)`
  width: 100%;
  border-radius: 1.5vw;

  div {
    max-width: 100%;
  }

  @media only screen and (max-width: 575px) {
    width: 143vw;
    height: auto;
    display: inline-block;
    border-radius: 5.5vw;
    margin-bottom: 2vw;
    box-shadow: 0 1vw 2vw rgba(0, 0, 0, 0.2);
  }
`;
