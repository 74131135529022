import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Descr, Wrapper } from "components/pages/course-page/offer/style";
import GranichLogoHands from "assets/svgs/granich-logo_hands.svg";
import GranichFigmaLogo from "assets/svgs/granich-figma/figma-logo.svg";
import GranichFigmaCursor from "assets/svgs/granich-figma/vadim-figma-cursor.svg";
import LilGranichFigmaCursor from "assets/svgs/granich-figma/lil-granich-figma-cursor.svg";
import ArsenyFigmaCursor from "assets/svgs/granich-figma/arseny-figma-cursor.svg";
import FigCloudIcon from "assets/svgs/granich-figma/fig-cloud-icon.svg";
import KittyIcon from "assets/svgs/granich-figma/kitty.svg";
import StarIcon from "assets/svgs/granich-figma/star-icon.svg";
import { CourseTag } from "components/index/courses-order-archive/style";
import { Container } from "components/style";
import BuyButtonComponent from "../buttons/BuyButtonComponent";

export const StyledGranichLogoHands = styled(GranichLogoHands)`
  width: 7.7vw;
  height: 6.5vw;

  @media only screen and (max-width: 575px) {
    width: 19vw;
    height: 18.5vw;
    margin-right: 1vw;
  }
`;

export const StyledGranichFigmaLogo = styled(GranichFigmaLogo)`
  width: 7vw;
  height: 6.8vw;
  position: relative;
  top: -0.3vw;

  @media only screen and (max-width: 575px) {
    width: 12vw;
    height: 16.5vw;
  }
`;

export const StyledGranichFigmaCursor = styled(GranichFigmaCursor)`
  position: absolute;
  bottom: 4.8vw;
  left: 12.3vw;
  width: 7.7vw;
  height: 5vw;

  @media only screen and (max-width: 575px) {
    width: 25vw;
    height: 15vw;
    bottom: 25.8vw;
    left: 6.8vw;
  }
`;

export const StyledLilGranichFigmaCursor = styled(LilGranichFigmaCursor)`
  position: absolute;
  bottom: 4.3vw;
  right: 25vw;
  width: 9.5vw;
  height: 4.5vw;

  @media only screen and (max-width: 575px) {
    width: 29vw;
    height: 15.2vw;
    bottom: 23vw;
    right: 4vw;
  }
`;

export const StyledArsenyFigmaCursor = styled(ArsenyFigmaCursor)`
  position: absolute;
  bottom: 9.4vw;
  right: 9vw;
  width: 9vw;
  height: 4.5vw;

  @media only screen and (max-width: 575px) {
    width: 28vw;
    height: 14.2vw;
    bottom: 44.8vw;
    right: 3vw;
  }
`;

export const StyledFigCloudIcon = styled(FigCloudIcon)`
  position: absolute;
  bottom: 12.8vw;
  right: 15.6vw;
  width: 6vw;
  height: 5.8vw;

  @media only screen and (max-width: 575px) {
    width: 19vw;
    height: 17.2vw;
    bottom: 59.8vw;
    right: 12.6vw;
  }
`;

export const StyledStarIcon = styled(StarIcon)`
  position: absolute;
  bottom: 13.7vw;
  right: 22.4vw;
  width: 2.25vw;
  height: 2.2vw;

  @media only screen and (max-width: 575px) {
    width: 10vw;
    height: 9.2vw;
    bottom: 32.8vw;
    right: 40.6vw;
  }
`;

export const StyledKittyIcon = styled(KittyIcon)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 7vw;
  height: 5.4vw;

  @media only screen and (max-width: 575px) {
    width: 35vw;
    height: 27.4vw;
    transform: translateX(-50%) scale(0.9, 1);
  }
`;

export const StyledWrapper = styled(Wrapper)`
  position: relative;
  top: -2vw;
  padding-top: 5.3vw;
  padding-bottom: 13vw;
  border-radius: 1.6vw;

  @media only screen and (max-width: 575px) {
    padding-top: 7vw;
    border-radius: 6.5vw;
    padding-bottom: 78vw;
    top: 0;
  }
`;

export const CourseLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

export const StyledContainer = styled(Container)`
  margin-top: 2vw;
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -0.2vw;
  div {
    margin-bottom: 0 !important;
  }

  @media only screen and (max-width: 575px) {
    padding: 0 3.5vw;
    margin-bottom: 3vw;
    flex-direction: row-reverse;
  }
`;

export const Tag = styled.div`
  display: flex;
  letter-spacing: -0.02vw;
  align-items: center;
  border: 0.15vw solid
    ${({ type }) =>
      type === "green" ? "rgba(91, 158, 89, 1)" : "rgba(150, 150, 150, 1)"};
  font-size: 1.1vw;
  padding: 0.4vw;
  margin: 0 0.36vw;
  border-radius: 0.5vw;
  line-height: 1.3;
  color: ${({ type }) =>
    type === "green" ? "rgba(91, 158, 89, 1)" : "rgba(150, 150, 150, 1)"};
  svg {
    margin-right: 0.45vw;
    width: 1.45vw;
    height: 1.4vw;
    min-width: 1.45vw;
  }

  @media only screen and (max-width: 575px) {
    font-size: 3.6vw;
    padding: 1.5vw 2vw;
    border-radius: 1.5vw;
    margin: 0 1vw;
    border-width: 0.4vw;

    svg {
      margin-right: 1.5vw;
      width: 4.5vw;
      height: 4.5vw;
      min-width: 4.5vw;
    }
  }
`;

export const MobileCompositionWrapper = styled.div`
  @media only screen and (max-width: 575px) {
    min-height: 80vw;
    height: 80vw;
    width: 100%;
    position: relative;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    margin: 0;
  }
`;

export const StyledDescr = styled(Descr)`
  text-align: center;
  width: 52vw;
  margin-top: 1.3vw;
  margin-bottom: 2vw;
  font-size: 1.36vw;

  span {
    display: block;
    font-size: 1.36vw;
  }

  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-top: 0;
    text-align: left;
    line-height: 1.35;
    margin-bottom: 6.5vw;

    span {
      font-size: 5vw;
    }
  }
`;

export const StyledTitle = styled.h1`
  color: var(--granich-black);
  font-weight: 800;
  letter-spacing: -0.01vw;
  font-size: 7.8vw;
  letter-spacing: -0.2vw;
  margin: 0 auto;
  margin-bottom: 0.8vw;
  text-align: center;

  @media only screen and (max-width: 575px) {
    font-size: 14.5vw;
    margin: 0 auto;
    line-height: 1;
    margin-bottom: 4vw;
  }
`;

export const VadimPopupImageWrapper = styled.div`
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 1.5vw;
  height: 1.5vw;
  min-width: 1.5vw;
  margin-right: 0.5vw;
  top: 0.1vw;

  @media only screen and (max-width: 575px) {
    width: 4.5vw;
    min-width: 4.5vw;
    height: 4.5vw;
    margin-right: 2vw;
  }
`;

export const VadimPopupImage = styled(GatsbyImage)`
  position: relative;
`;

export const GranichDialogPopup = styled.div`
  position: absolute;
  bottom: 10.5vw;
  left: 10.5vw;
  width: 16vw;
  display: flex;
  align-content: start;
  background: white;
  border-radius: 0.85vw 0.85vw 0.2vw 0.85vw;
  box-shadow: 0 0.2vw 1.5vw rgba(0, 0, 0, 0.1);
  padding: 1.4vw 1.1vw;

  @media only screen and (max-width: 575px) {
    padding: 3.5vw;
    border-radius: 2.5vw 2.5vw 1vw 2.5vw;
    box-shadow: 0 1vw 3vw rgba(0, 0, 0, 0.1);
    bottom: 46vw;
    left: 4.7vw;
    width: 51vw;
  }
`;

export const PopupInfo = styled.div``;
export const PopupName = styled.div`
  font-weight: bold;
  margin-bottom: 0.1vw;
  font-size: 0.85vw;

  @media only screen and (max-width: 575px) {
    font-size: 2.2vw;
    margin-bottom: 0.5vw;
  }
`;
export const PopupText = styled.div`
  font-size: 0.83vw;
  line-height: 1.4;

  @media only screen and (max-width: 575px) {
    font-size: 2.5vw;
  }
`;

export const PopupTiming = styled.div`
  position: absolute;
  top: 1.4vw;
  right: 1vw;
  font-size: 0.8vw;
  color: rgba(150, 150, 150, 1);

  @media only screen and (max-width: 575px) {
    top: 3vw;
    right: 3vw;
    font-size: 2.5vw;
  }
`;

export const StyledFixedBuyButtonComponent = styled(BuyButtonComponent)`
  position: fixed;
  left: 50%;
  bottom: 1.5vw;
  transform: translateX(-50%);
  z-index: 999;

  @media only screen and (max-width: 575px) {
    bottom: 22vw;
  }
`;

export const StyledTag = styled(CourseTag)`
  margin-left: 1vw;
`;
