import React from "react";

import * as SC from "./style";

const Card = ({ title, preview, type }) => {
  if (type === "custom") {
    return <div style={{ position: "relative" }}>{preview}</div>;
  }

  return (
    <SC.CardWrapper>
      <SC.CardTitle>{title}</SC.CardTitle>
      <SC.CardContent>{preview}</SC.CardContent>
    </SC.CardWrapper>
  );
};

export default Card;
