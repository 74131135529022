import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useStaticQuery, graphql } from "gatsby";
import LibraryIllustration from "assets/svgs/granich-figma/table-of-contents/library.svg";
import FigmaLogo from "assets/svgs/granich-figma/table-of-contents/figma-logo.svg";
import * as SC from "./style";

const useGranichFigmaTableOfContent = () => {
  const image = useStaticQuery(graphql`
    query granichFigmaTableOfContentsImage {
      graphics: file(
        relativePath: { eq: "granich-figma/table-of-contents/graphics.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 400, quality: 90, layout: CONSTRAINED)
        }
      }
      effects: file(
        relativePath: { eq: "granich-figma/table-of-contents/effects.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 400, quality: 90, layout: CONSTRAINED)
        }
      }
      styles: file(
        relativePath: { eq: "granich-figma/table-of-contents/styles.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
        }
      }
      phone: file(
        relativePath: { eq: "granich-figma/table-of-contents/phone.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 400, quality: 90, layout: CONSTRAINED)
        }
      }
    }
  `);

  const graphics = image.graphics.childImageSharp.gatsbyImageData;
  const effects = image.effects.childImageSharp.gatsbyImageData;
  const styles = image.styles.childImageSharp.gatsbyImageData;
  const phone = image.phone.childImageSharp.gatsbyImageData;
  const content = [
    {
      title: "Инструменты Фигмы",
      preview: <SC.StyledInstrumentsIllustration />,
      id: uuidv4(),
    },
    {
      title: "Сетки всякие важные",
      preview: <SC.StyledGridsIllustration />,
      id: uuidv4(),
    },
    {
      title: "Работа с текстом",
      preview: <SC.StyledTextIllustration />,
      id: uuidv4(),
    },
    {
      title: "Добавление графики",
      preview: <SC.StyledGraphicsImage alt="graphics-image" image={graphics} />,
      id: uuidv4(),
    },
    {
      title: "Эффектные эффекты",
      preview: <SC.StyledEffectsImage alt="effects-image" image={effects} />,
      id: uuidv4(),
    },
    {
      title: "Библиотеки",
      preview: <LibraryIllustration />,
      id: uuidv4(),
    },
    {
      title: "Авто-лэйауты",
      preview: <SC.StyledAutoLayoutsIllustration />,
      id: uuidv4(),
    },
    {
      title: "Компоненты + инстансы",
      preview: <SC.StyledComponentsIllustration />,
      id: uuidv4(),
    },
    {
      title: "Кнопки: их виды и состояния",
      preview: <SC.StyledButtonsIllustration />,
      id: uuidv4(),
    },
    {
      title: "4 вида свойств компонентов",
      preview: <SC.StyledTypesOfComponentsIllustration />,
      id: uuidv4(),
    },
    {
      title: "Прототипы и создание интерактивных макетов",
      preview: <SC.StyledPrototypingIllustration />,
      id: uuidv4(),
    },
    {
      type: "custom",
      preview: (
        <SC.StyledIPhone
          alt="phone"
          style={{ position: "absolute" }}
          image={phone}
        />
      ),
      id: uuidv4(),
    },
    {
      title: "Тестирование мобильных макетов в приложении Фигмы на телефоне",
      preview: (
        <SC.TestingAppsIllustrationWrapper>
          <FigmaLogo />
          <SC.StyledAppsIllustration />
        </SC.TestingAppsIllustrationWrapper>
      ),
      id: uuidv4(),
    },
    {
      title: "Подготовка дизайн-макета для передачи в разработку",
      preview: <SC.StyledDevelopmentIllustration />,
      id: uuidv4(),
    },
    {
      title: "Стили цвета",
      preview: <SC.StyledStyleIllustration />,
      id: uuidv4(),
    },
    {
      type: "custom",
      preview: (
        <SC.TooltopWrapper>
          <SC.StyledTooltip />
        </SC.TooltopWrapper>
      ),
      id: uuidv4(),
    },
  ];

  return content;
};

export default useGranichFigmaTableOfContent;
