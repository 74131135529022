import styled from "styled-components";
import Img from "gatsby-image";
import TimerIllustration from "assets/svgs/granich-figma/lesson-duration-illustration.svg";
import { Container, NewWrapper, Section } from "components/style";

export const StyledSection = styled(Section)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 10vw;
  }
`;

export const StyledContainer = styled(Container)``;

export const StyledWrapper = styled(NewWrapper)`
  @media only screen and (max-width: 575px) {
    border-radius: 6.5vw;
    padding-right: 1vw;
  }
`;

export const SectionText = styled.div`
  font-size: 4.52vw;
  font-weight: bold;
  letter-spacing: -0.12vw;
  line-height: 1.2;

  img {
    width: 4.3vw;
    min-width: 4.3vw;
    position: relative;
    top: 0.7vw;
  }

  @media only screen and (max-width: 575px) {
    font-size: 8.25vw;
    line-height: 1.1;

    img {
      width: 9vw;
      min-width: 9vw;
      position: relative;
      top: 1.5vw;
    }
  }
`;

export const SlidesImage = styled(Img)`
  width: 100%;

  div {
    max-width: 100%;
  }
`;

export const StyledTimerIllustration = styled(TimerIllustration)`
  width: 100%;
  height: 38vw;
  position: relative;
  margin-top: -2vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 3vw;
    margin-top: 2vw;
    padding-right: 3vw;
  }
`;
