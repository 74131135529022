import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as SC from "./style";

const GranichFigmaQualitySlides = () => {
  const image = useStaticQuery(graphql`
    query granichFigmaQualitySlidesImage {
      slide1: file(relativePath: { eq: "granich-figma/slides/01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide2: file(relativePath: { eq: "granich-figma/slides/02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide3: file(relativePath: { eq: "granich-figma/slides/03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide4: file(relativePath: { eq: "granich-figma/slides/04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide5: file(relativePath: { eq: "granich-figma/slides/05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide6: file(relativePath: { eq: "granich-figma/slides/06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const slide1 = image.slide1.childImageSharp.fluid;
  const slide2 = image.slide2.childImageSharp.fluid;
  const slide3 = image.slide3.childImageSharp.fluid;
  const slide4 = image.slide4.childImageSharp.fluid;
  const slide5 = image.slide5.childImageSharp.fluid;
  const slide6 = image.slide6.childImageSharp.fluid;

  return (
    <SC.StyledSection>
      <SC.StyledContainer>
        <SC.StyledWrapper>
          <SC.StyledSectionTitle>
            Уйма качественно оформленных слайдов{" "}
            <img src="/images/emojis/_star.png" loading="lazy" alt="star" />
          </SC.StyledSectionTitle>
          <SC.SlidesWrapper>
            <SC.SlideImage fluid={slide1} style={{ borderRadius: "0.5vw" }} />
            <SC.SlideImage fluid={slide2} style={{ borderRadius: "0.5vw" }} />
            <SC.SlideImage fluid={slide3} style={{ borderRadius: "0.5vw" }} />
            <SC.SlideImage fluid={slide4} style={{ borderRadius: "0.5vw" }} />
            <SC.SlideImage fluid={slide5} style={{ borderRadius: "0.5vw" }} />
            <SC.SlideImage fluid={slide6} style={{ borderRadius: "0.5vw" }} />
          </SC.SlidesWrapper>
        </SC.StyledWrapper>
      </SC.StyledContainer>
    </SC.StyledSection>
  );
};

export default GranichFigmaQualitySlides;
