import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as SC from "./style";

const GranichFigmaUIKitExample = () => {
  const image = useStaticQuery(graphql`
    query granichFigmaUiKitExampleImage {
      placeholderImage: file(
        relativePath: { eq: "granich-figma/ui-kit-example.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const imageData = image.placeholderImage.childImageSharp.fluid;

  return (
    <SC.StyledSectiom>
      <SC.StyledContainer>
        <SC.StyledWrapper>
          <SC.StyledSectionTitle>
            Вот такой UI-кит соберём
          </SC.StyledSectionTitle>
          <SC.SectionSubtitle>
            <span>
              Тут кнопки всякие, окно входа, версии десктоп и мобайл, иконочки
              и прочий фарш.
            </span>
            <span>
              Там это всё ещё и с вэриантс сделано, по секциям разбито,
              прототипы подключены.
            </span>
          </SC.SectionSubtitle>
          <SC.SlidesImageWrapper>
            <SC.SlidesImage fluid={imageData} />
          </SC.SlidesImageWrapper>
        </SC.StyledWrapper>
      </SC.StyledContainer>
    </SC.StyledSectiom>
  );
};

export default GranichFigmaUIKitExample;
