import styled from "styled-components";
import Img from "gatsby-image";
import ArrowRightIcon from "assets/svgs/granich-figma/who-fits/arrow-right-icon.svg";
import WebPixelIcon from "assets/svgs/granich-figma/who-fits/web-pixel-icon.svg";
import { Container, Section, SectionTitle, Wrapper } from "components/style";

export const StyledSectiom = styled(Section)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 10vw;
  }
`;

export const StyledContainer = styled(Container)``;

export const StyledWrapper = styled(Wrapper)`
  padding: 2vw 3vw;
  border-radius: 1.5vw;

  @media only screen and (max-width: 575px) {
    padding: 6vw 6vw 6vw 6vw;
    border-radius: 2.5vw;
  }
`;

export const StyledSectionTitle = styled(SectionTitle)`
  display: flex;
  align-items: center;
  font-size: 4.5vw;
  margin-bottom: 1.5vw;
  span {
    position: relative;
    color: var(--granich-red);
    font-weight: bold;
    transform: rotate(-10deg);
    display: block;
    margin: 0 0.5vw;
  }

  @media only screen and (max-width: 575px) {
    display: block;
    font-size: 7.9vw;
    letter-spacing: 0;

    span {
      display: inline-block;
      font-size: 7.9vw;
    }
  }
`;

export const SlidesScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1vw;

  @media only screen and (max-width: 575px) {
    width: 100vw;
    height: 64vw;
    left: -11vw;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 0 11vw;
    top: 2vw;
    margin-bottom: 0;
    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      display: none;
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar-track {
      display: none;
    }
  }
`;

export const SlidesWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;

  @media only screen and (max-width: 575px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 11vw;
    flex-direction: row;
  }
`;

export const SlidesFirstWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  gap: 1.5vw;
  margin-bottom: 1.5vw;
  width: 100%;

  .slider-image-wrapper {
    height: 29vw;
  }

  @media only screen and (max-width: 575px) {
    display: flex;
    align-items: center;
    gap: 0;
    width: auto;
    margin-bottom: 0;
    .slider-image-wrapper {
      height: 58vw;
    }
  }
`;

export const SlidesSecondWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1.6fr;
  gap: 1.5vw;
  width: 100%;

  .slider-image-wrapper {
    height: 24vw;
  }

  @media only screen and (max-width: 575px) {
    display: flex;
    align-items: center;
    position: relative;
    gap: 0;

    .slider-image-wrapper {
      height: 58vw;
    }
  }
`;

export const SlideImage = styled(Img)`
  position: relative;
  box-shadow: 0 1vw 1vw rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
`;

export const SlideImageWrapper = styled.div.attrs(() => ({
  className: "slider-image-wrapper",
}))`
  position: relative;
  border: 0.1vw solid #969696;
  border-radius: 1vw;
  overflow: hidden;
  span {
    font-size: 1.6vw;
    position: absolute;
    bottom: 1.5vw;
    background: white;
    border: 0.1vw solid #969696;
    left: 50%;
    transform: translateX(-50%);
    padding: 1vw 1.8vw;
    border-radius: 0.6vw;
    white-space: nowrap;
    font-weight: bold;
    line-height: 1;
  }

  @media only screen and (max-width: 575px) {
    height: 58vw;
    min-width: 100vw;
    margin-right: 2.5vw;
    border-radius: 3vw;
    border: 0.5vw solid #969696;

    span {
      font-size: 3.7vw;
      bottom: 3vw;
      padding: 2vw 2.5vw;
      border-radius: 2.5vw;
      border-width: 0.5vw;
    }

    ${({ $isLast }) =>
      $isLast &&
      `
      margin-right: 9vw;
    `}
  }
`;

export const SlideImageWrapperPelevin = styled(SlideImageWrapper)`
  div {
    max-width: 100% !important;
  }
  @media only screen and (max-width: 575px) {
    min-width: 48vw;
    width: 48vw;
  }
`;

export const SlideImageWrapperClimbers = styled(SlideImageWrapper)`
  img {
    transform: scale(1.3);
    top: -4vw !important;
  }
  @media only screen and (max-width: 575px) {
    min-width: 81vw;
    width: 81vw;
    img {
      transform: scale(1.25);
      top: -7vw !important;
      left: 2vw !important;
    }
  }
`;

export const SlideImageWrapperMushrooms = styled(SlideImageWrapper)`
  @media only screen and (max-width: 575px) {
    min-width: 60vw;
    width: 60vw;
  }
`;

export const SlideImageWrapperRaisin = styled(SlideImageWrapper)`
  @media only screen and (max-width: 575px) {
    min-width: 60vw;
  }
`;

export const WhoFitsWrapper = styled.div`
  font-size: 4.2vw;
  letter-spacing: -0.1vw;
  line-height: 1.35;
  i {
    font-family: EB Garamond;
    font-size: 5.3vw;
    letter-spacing: -0.1vw;
    line-height: 0;
  }

  span {
    display: inline-flex;
    align-items: center;
    height: 100%;

    img {
      width: 2vw;
      vertical-align: middle;
    }
  }

  @media only screen and (max-width: 575px) {
    font-size: 4.8vw;
    letter-spacing: -0.1vw;
    line-height: 1.4;

    i {
      font-size: 6vw;
    }

    span {
      img {
        width: 5vw;
      }
    }
  }
`;

export const EmojisInOneRowWrapper = styled.div`
  display: inline-flex;
  padding: 1vw;
  div {
    margin-left: -0.6vw;

    :first-child {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 575px) {
    padding: 1vw;
    div {
      margin-left: -0.8vw;
    }
  }
`;

export const EmojiWrapper = styled.div`
  width: 4.1vw;
  height: 4.1vw;
  background: white;
  border-radius: 50%;
  border: 0.1vw solid grey;
  padding: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;

  img {
    width: 100%;
  }

  @media only screen and (max-width: 575px) {
    width: 4.8vw;
    height: 4.8vw;
    padding: 0.8vw;
  }
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
  width: 3vw;
  min-width: 3vw;
  margin-right: 1vw;

  @media only screen and (max-width: 575px) {
    width: 3.7vw;
    min-width: 3.7vw;
    margin-right: 1vw;
  }
`;

export const StyledWebPixelIcon = styled(WebPixelIcon)`
  width: 6vw;
  min-width: 6vw;
  height: 3vw;

  @media only screen and (max-width: 575px) {
    width: 7.3vw;
    min-width: 7.3vw;
    height: 3.7vw;
  }
`;

export const RunningImg = styled.img`
  width: 4.5vw;
  min-width: 4.5vw;
  position: relative;
  top: -0.1vw;
  vertical-align: middle;
  @media only screen and (max-width: 575px) {
    width: 5.5vw;
    min-width: 5.5vw;
    top: -0.5vw;
  }
`;

export const GameImg = styled.img`
  vertical-align: middle;
  position: relative;
  left: 1vw;
  width: 3.2vw !important;
  top: 1.5vw;
  @media only screen and (max-width: 575px) {
    width: 4vw !important;
    min-width: 4vw;
    top: 2.5vw;
    left: 1.2vw;
  }
`;

export const ArrowsWrapper = styled.div`
  padding: 0 0 0 1vw;
  position: relative;
  top: 0.1vw;
  @media only screen and (max-width: 575px) {
    padding: 1vw;
  }
`;

export const UnderlinedItalic = styled.i`
  position: relative;
  line-height: 0;
  :after {
    content: "";
    width: 100%;
    height: 0.3vw;
    position: absolute;
    left: 0;
    bottom: 0.8vw;
    background: var(--granich-black);
  }
`;
