import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { GatsbyImage } from "gatsby-plugin-image";
import MagicWandIcon from "assets/svgs/granich-figma/magic-wand-icon.svg";
import { Container, Section, SectionTitle } from "components/style";

const authorCardBorderRadius = "1.5vw";
const authorCardBorderRadiusMobile = "5.5vw";

export const StyledSection = styled(Section)`
  display: block;
  ${({ isShowOnMobile }) =>
    isShowOnMobile &&
    `
  display: none;
  `}
  @media only screen and (max-width: 575px) {
    margin-bottom: 10vw;
    display: block;
    ${({ isShowOnDesktop }) =>
      isShowOnDesktop &&
      `
      display: none;
    `}
  }
`;

export const StyledContainer = styled(Container)``;

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 575px) {
    display: block;
    margin-bottom: 3vw;
  }
`;

export const StyledSectionTitle = styled(SectionTitle)`
  font-size: 4.6vw;
  padding-left: 1vw;
  letter-spacing: -0.12vw;
  margin-right: 1.2vw;
  margin-bottom: 1.6vw;

  @media only screen and (max-width: 575px) {
    font-size: 8vw;
    padding-left: 0;
    margin-right: 0;
    margin-bottom: 5vw;
  }
`;

export const SectionSubTitle = styled.div`
  font-size: 1.3vw;
  color: rgba(150, 150, 150, 1);

  @media only screen and (max-width: 575px) {
    font-size: 5vw;
    margin-bottom: 6vw;
  }
`;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2vw;

  @media only screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-row-gap: 6vw;
  }
`;

export const AuthorCard = styled.div`
  height: 28.7vw;
  position: relative;

  @media only screen and (max-width: 575px) {
    height: 100vw;
  }
`;

export const AuthorOverflow = styled.div`
  overflow: hidden;
  border-radius: ${authorCardBorderRadius};
  height: 100%;
  box-shadow: 0 0.4vw 0.6vw rgba(0, 0, 0, 0.16);

  @media only screen and (max-width: 575px) {
    box-shadow: 0 0.4vw 2.6vw rgba(0, 0, 0, 0.16);
    border-radius: ${authorCardBorderRadiusMobile};
  }
`;

export const AuthorImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

export const MagicWandIconButton = styled.button`
  border-radius: 50%;
  background: white;
  width: 4.1vw;
  height: 4.1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 1.5vw;
  left: 1.5vw;
  box-shadow: 0 0.5vw 0.6vw rgba(0, 0, 0, 0.2);

  :hover {
    cursor: pointer;
    svg {
      fill: var(--granich-red);
    }
  }

  @media only screen and (max-width: 575px) {
    width: 16vw;
    height: 16vw;
    top: 5.5vw;
    left: 5.5vw;
    box-shadow: 0 1.5vw 2.6vw rgba(0, 0, 0, 0.2);
  }
`;

export const StyledMagicWandIcon = styled(MagicWandIcon)`
  width: 2.4vw;
  height: 2.4vw;
  fill: var(--granich-black);

  @media only screen and (max-width: 575px) {
    width: 9vw;
    min-width: 9vw;
    height: 9vw;
  }
`;

export const AuthorInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5vw 1.9vw 1.9vw 1.9vw;
  border-radius: 0 0 ${authorCardBorderRadius} ${authorCardBorderRadius};
  background: linear-gradient(
    0deg,
    rgba(41, 41, 41, 1) 0%,
    rgba(41, 41, 41, 0) 100%
  );

  @media only screen and (max-width: 575px) {
    padding: 6vw;
    border-radius: 0 0 ${authorCardBorderRadiusMobile}
      ${authorCardBorderRadiusMobile};
  }
`;

export const AuthorName = styled.div`
  color: rgba(240, 240, 240, 1);
  font-size: 1.8vw;

  @media only screen and (max-width: 575px) {
    font-size: 7vw;
  }
`;

export const AuthorTags = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 2vw;
  }
`;

export const AuthorTag = styled.div`
  background: rgba(150, 150, 150, 1);
  border-radius: 0.6vw;
  color: white;
  padding: 0.65vw;
  margin-right: 0.7vw;
  letter-spacing: -0.02vw;
  white-space: nowrap;
  font-size: 1.1vw;
  :last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 575px) {
    border-radius: 1.7vw;
    padding: 2vw 2.7vw;
    font-size: 3.7vw;
    margin-right: 1.8vw;
  }
`;

export const StyledCSSTransition = styled(CSSTransition)`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 17;

  &.author-transition-enter {
    top: 0;
    opacity: 0;
  }

  &.author-transition-enter-active {
    top: 0;
    transition: top 300ms, opacity 300ms;
    opacity: 1;
  }

  &.author-transition-exit {
    top: 0;
    opacity: 1;
  }

  &.author-transition-exit-active {
    top: 0;
    transition: top 300ms, opacity 300ms;
    opacity: 0;
  }
`;

export const AdditionalInfoWrapper = styled.div`
  background: rgba(41, 41, 41, 0.6);
  padding: 2.4vw;
  border-radius: ${authorCardBorderRadius};
  border: 0.15vw solid rgba(150, 150, 150, 1);
  backdrop-filter: blur(0.6vw);

  @media only screen and (max-width: 575px) {
    padding: 7.5vw;
    border-width: 0.8vw;
    border-radius: ${authorCardBorderRadiusMobile};
    backdrop-filter: blur(5vw);
  }
`;

export const AdditionalInfoText = styled.div`
  color: white;

  span {
    color: white;
    display: block;
    margin-bottom: 0.8vw;
    line-height: 1.5;
    font-size: 1.3vw;
    :last-child {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 575px) {
    span {
      margin-bottom: 2.5vw;
      font-size: 5vw;
    }
  }
`;

export const AdditionalInfoTitle = styled.div`
  color: white;
  margin-bottom: 1.4vw;
  font-size: 2.2vw;
  font-weight: 600;
  line-height: 1;

  @media only screen and (max-width: 575px) {
    margin-bottom: 6vw;
    font-size: 8vw;
    max-width: 90%;
  }
`;

export const AdditionalInfoCloseButton = styled.button`
  background: none;
  display: flex;
  align-items: center;
  margin-bottom: 2vw;

  span {
    color: white;
    font-size: 1.3vw;

    :hover {
      text-decoration: underline;
    }
  }

  :hover {
    cursor: pointer;
  }

  svg {
    width: 1.2vw;
    height: 1.2vw;
    margin-right: 0.6vw;
    fill: white;
  }

  @media only screen and (max-width: 575px) {
    margin-bottom: 8vw;

    span {
      font-size: 5vw;
    }

    svg {
      width: 4vw;
      height: 4vw;
      margin-right: 2vw;
    }
  }
`;
