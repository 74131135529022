import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import FigmaIcon from "assets/svgs/granich-figma/chat/figma-icon.svg";
import RedFaceIcon from "assets/svgs/granich-figma/chat/red-face-icon.svg";
import NormalFaceIcon from "assets/svgs/granich-figma/chat/normal-face-icon.svg";
import { Container, SectionTitle, Wrapper, Section } from "components/style";

export const StyledSectiom = styled(Section)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 0;
  }
`;

export const MobileLayout = styled.div`
  display: none;
  @media only screen and (max-width: 575px) {
    display: block;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const DesktopLayout = styled.div`
  display: block;
  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const MobileIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;

  svg {
    width: 13.5vw;
    height: 13vw;
    min-width: 13.5vw;
    margin-right: 2vw;

    :first-child {
      width: 15.5vw;
      height: 15vw;
      min-width: 15.5vw;
    }

    :last-child {
      margin-right: 0;
    }
  }
`;

export const StyledContainer = styled(Container)``;

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    position: relative;
    top: 0.2vw;

    :first-child {
      top: 0;
    }
  }

  @media only screen and (max-width: 575px) {
    flex-direction: column-reverse;
    align-items: start;
  }
`;

export const StyledContainerWrapper = styled(Wrapper)`
  border-radius: 1.5vw;
  padding: 3vw;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 575px) {
    border-radius: 5.5vw;
    background: white;
    padding: 6vw;
    flex-direction: column-reverse;
  }
`;

export const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 0;
  font-size: 4.5vw;
  margin-right: 0.4vw;

  @media only screen and (max-width: 575px) {
    font-size: 8vw;
    letter-spacing: 0;
  }
`;

export const ChatsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2vw;
  margin-bottom: 1vw;

  @media only screen and (max-width: 575px) {
    display: flex;
    flex-direction: column;
    transform: scale(1.07);
  }
`;

export const ChatImageWrapper = styled.div`
  overflow: hidden;
  height: 15.5vw;
  border: 0.2vw solid #f0f0f0;
  border-radius: 1vw;

  div {
    max-width: 100% !important;
  }
`;

export const ChatImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  transform: scale(1.02);
  @media only screen and (max-width: 575px) {
    height: auto;
    margin-bottom: 3vw;
  }
`;

export const ChatBubblesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.2vw solid #f0f0f0;
  border-radius: 1.4vw;
  padding: 1.2vw;

  @media only screen and (max-width: 575px) {
    border-radius: 3.5vw;
    padding: 2.5vw;
    :first-child {
      margin-bottom: 3vw;
    }
  }
`;

const ChatBubbleStyle = `
  border-radius: 1.4vw;
  color: white;
  padding: 0.8vw 1.3vw;
  width: auto;
  font-size: 1.6vw;
  line-height: 1.3;

  img {
    width: 1.8vw;
    position: relative;
    top: 0.25vw;
  }

  @media only screen and (max-width: 575px) {
    font-size: 4vw;
    border-radius: 3.5vw;
    padding: 3vw;

    img {
      width: 4vw;
      position: relative;
      top: 0.5vw;
    }
  }
`;

export const ChatBubbleLeft = styled.div`
  ${ChatBubbleStyle};
  max-width: 94%;
  background: #c2c2c2;
  margin-bottom: 1vw;
  border-bottom-left-radius: 0.3vw;

  @media only screen and (max-width: 575px) {
    border-bottom-left-radius: 1vw;
    margin-bottom: 3vw;
  }
`;

export const ChatBubbleRight = styled.div`
  ${ChatBubbleStyle};
  max-width: 72%;
  background: #4b96f5;
  margin-left: auto;
  border-bottom-right-radius: 0.3vw;

  @media only screen and (max-width: 575px) {
    border-bottom-right-radius: 1vw;
    max-width: 80%;
  }
`;

export const StyledDesktopFigmaIcon = styled(FigmaIcon)`
  width: 4.7vw;
  height: 4.7vw;
  min-width: 4.7vw;
  margin-right: 1vw;
  display: inline;
  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledMobileFigmaIcon = styled(FigmaIcon)`
  display: none;
  @media only screen and (max-width: 575px) {
    width: 13vw;
    min-width: 13vw;
    height: 13vw;
    display: inline;
    margin-right: 2vw;
  }
`;

export const StyledRedFaceIcon = styled(RedFaceIcon)`
  width: 3.5vw;
  height: 3.5vw;
  min-width: 3.6vw;
  margin-right: 0.8vw;

  @media only screen and (max-width: 575px) {
    width: 13vw;
    height: 13vw;
    min-width: 13vw;
    margin-right: 2vw;
  }
`;

export const StyledNormalFaceIcon = styled(NormalFaceIcon)`
  width: 3.5vw;
  height: 3.5vw;
  min-width: 3.6vw;

  @media only screen and (max-width: 575px) {
    width: 13vw;
    height: 13vw;
    min-width: 13vw;
  }
`;

export const Text = styled.div`
  font-size: 2.2vw;
  line-height: 1.5;
  margin-top: 1vw;
  @media only screen and (max-width: 575px) {
    font-size: 4.94vw;
    margin-top: 3vw;
    margin-bottom: 8vw;
  }
`;

export const IconsWrapper = styled.div`
  display: inline;

  @media only screen and (max-width: 575px) {
    margin-bottom: 3vw;
  }
`;
