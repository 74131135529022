import React from "react";

import * as SC from "./style";
import Chat from "./Chat";

const GranichFigmaChat = () => {
  const chats = [
    {
      bubble1: (
        <>
          Авто-лэйаут опять меня обижает{" "}
          <img src="/images/emojis/_cry.png" loading="lazy" alt="cry" />
        </>
      ),
      bubble2: "Он всех обижает, погнали разберёмся",
    },
    {
      bubble1: "А почему у меня не обрезаются объекты внутри фрейма?",
      bubble2: (
        <>
          Попробуй поставить галочку в Clip Content{" "}
          <img src="/images/emojis/_check.png" loading="lazy" alt="check" />
        </>
      ),
    },
  ];

  return (
    <SC.StyledSectiom>
      <SC.StyledContainer>
        <SC.StyledContainerWrapper>
          <SC.ChatsWrapper>
            {chats.map(({ bubble1, bubble2 }, idx) => (
              <Chat key={idx} bubble1={bubble1} bubble2={bubble2} />
            ))}
          </SC.ChatsWrapper>
          <SC.MainWrapper>
            <SC.SectionTitleWrapper>
              <SC.StyledDesktopFigmaIcon />
              <SC.StyledSectionTitle>
                <SC.StyledSectionTitle>Чат по Фигме</SC.StyledSectionTitle>
              </SC.StyledSectionTitle>
              <SC.IconsWrapper>
                <SC.StyledMobileFigmaIcon />
                <SC.StyledRedFaceIcon />
                <SC.StyledNormalFaceIcon />
              </SC.IconsWrapper>
            </SC.SectionTitleWrapper>
            <SC.Text>
              Все соавторы этого мастер-класса будут с вами в чате Школы
              по Фигме. Вы всегда сможете получить тут поддержку.
            </SC.Text>
          </SC.MainWrapper>
        </SC.StyledContainerWrapper>
      </SC.StyledContainer>
    </SC.StyledSectiom>
  );
};

export default GranichFigmaChat;
