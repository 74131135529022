import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as SC from "./style";

const GranichFigmaWhyShouldIBuy = () => {
  const image = useStaticQuery(graphql`
    query granichFigmaIrritatedCatImage {
      placeholderImage: file(
        relativePath: { eq: "granich-figma/irritated-cat.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 90, layout: CONSTRAINED)
        }
      }
    }
  `);

  const imageData = image.placeholderImage.childImageSharp.gatsbyImageData;

  return (
    <SC.StyledSection>
      <SC.StyledContainer>
        <SC.MainWrapper>
          <SC.IrritatedCatWrapper>
            <SC.IrritatedCatImg
              image={imageData}
              style={{ backgroundSize: "cover" }}
              alt="irritated cat"
            />
            <SC.IrritatedCatText>
              Пфффф, а зачем мне покупать ваш мастер-класс по Фигме, когда
              в инете дофига бесплатных?
            </SC.IrritatedCatText>
          </SC.IrritatedCatWrapper>
          <SC.GranichExplainsWrapper>
            <SC.GranichExplainsText>
              А вот сейчас и разберёмся
            </SC.GranichExplainsText>
            <SC.StyledGranichExplainsIcon />
          </SC.GranichExplainsWrapper>
        </SC.MainWrapper>
      </SC.StyledContainer>
    </SC.StyledSection>
  );
};

export default GranichFigmaWhyShouldIBuy;
