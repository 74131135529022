import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as SC from "./style";
import Review from "./Review";

const GranichFigmaReviews = () => {
  const images = useStaticQuery(graphql`
    query granichFigmaReviewsImage {
      review1: file(relativePath: { eq: "granich-figma/reviews/01.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 90, layout: CONSTRAINED)
        }
      }
      review2: file(relativePath: { eq: "granich-figma/reviews/02.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 90, layout: CONSTRAINED)
        }
      }
      review3: file(relativePath: { eq: "granich-figma/reviews/03.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 90, layout: CONSTRAINED)
        }
      }
    }
  `);

  const review1 = images.review1.childImageSharp.gatsbyImageData;
  const review2 = images.review2.childImageSharp.gatsbyImageData;
  const review3 = images.review3.childImageSharp.gatsbyImageData;

  const reviews = [
    {
      reviewer: "Алина Завертайлова",
      image: review1,
      text: (
        <>
          <span>
            Раньше я думала, что могу получать удовольствие только от еды, сна
            и секса.
          </span>
          <span>После этого мастер-класса поняла, что ещё и от обучения!</span>
        </>
      ),
    },
    {
      reviewer: "Кирилл Молчанов",
      image: review2,
      text: (
        <>
          <span>
            Я бы сказал, что этот мастер-класс как хорошие хинкали, а не
            питерская шаверма
          </span>
          <span>P.S. А добавка будет? 🤔</span>
        </>
      ),
    },
    {
      reviewer: "Алиса Ахэгао",
      image: review3,
      text: (
        <>
          <span>
            Лянёёёёж такое всё азхахза лайк подписка ура ^_____^ коты захватят
            мир и этот мастер-класс тоже 0_0
          </span>
        </>
      ),
    },
  ];

  return (
    <SC.StyledSectiom>
      <SC.StyledContainer>
        <SC.SectionTitleWrapper>
          <SC.StyledSectionTitle>Отзывы</SC.StyledSectionTitle>
          <SC.SectionSubTitle>
            Вымышленные отзывы, которые станут вашими после прохождения этого
            мастер-класса
          </SC.SectionSubTitle>
        </SC.SectionTitleWrapper>
        <SC.MainWrapperScroll>
          <SC.MainWrapper>
            {reviews.map(({ reviewer, image, text }, idx) => (
              <Review key={idx} reviewer={reviewer} image={image} text={text} />
            ))}
          </SC.MainWrapper>
        </SC.MainWrapperScroll>
      </SC.StyledContainer>
    </SC.StyledSectiom>
  );
};

export default GranichFigmaReviews;
