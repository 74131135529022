import React from "react";
import * as SC from "./QandAItem";

const ContentComponent = ({ firstColumn, secondColumn, isWrap }) => (
  <SC.ContentWrapper isWrap={isWrap}>
    <SC.TextWrapper>
      {firstColumn && (
        <SC.CategoryColumn isWrap={isWrap} $isOneColumn={!secondColumn}>
          {firstColumn.map((item, idx) => (
            <React.Fragment key={idx}>{item}</React.Fragment>
          ))}
        </SC.CategoryColumn>
      )}
      {secondColumn && (
        <SC.CategoryColumn isWrap={isWrap}>
          {secondColumn.map((item, idx) => (
            <React.Fragment key={idx}>{item}</React.Fragment>
          ))}
        </SC.CategoryColumn>
      )}
    </SC.TextWrapper>
  </SC.ContentWrapper>
);

export default ContentComponent;
