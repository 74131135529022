import React, { forwardRef } from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import * as SC from "./style";

const BuyButtonComponent = forwardRef(({ to, className }, ref) => (
  <SC.StyledButton ref={ref} onClick={() => scrollTo(to)} className={className}>
    <SC.StyledGranichFigmaLogoFilled />
    <SC.StyledCartIcon />
    Купить
  </SC.StyledButton>
));

export default BuyButtonComponent;
