import React from "react";
import useAdaptive from "hooks/useAdaptive";

import * as SC from "./style";
import Card from "./Card";
import useGranichFigmaTableOfContent from "./useGranichFigmaTableOfContent";

const GranichFigmaTableOfContents = () => {
  const content = useGranichFigmaTableOfContent();

  const mobileContent = [
    ...content.slice(0, 13),
    ...content.slice(15, 16),
    ...content.slice(13, 15),
  ];

  const { isMobile, isDesktop } = useAdaptive();

  return (
    <SC.StyledSection>
      <SC.StyledContainer>
        <SC.StyledSectionTitle>Содержание мастер-класса</SC.StyledSectionTitle>
        <SC.MainWrapper>
          {isDesktop && (
            <SC.SectionOneDesktop>
              {content.map(({ title, preview, type, id }) => (
                <Card key={id} title={title} preview={preview} type={type} />
              ))}
            </SC.SectionOneDesktop>
          )}
          {isMobile && (
            <SC.SectionOneMobile>
              {mobileContent.map(({ title, preview, type, id }) => (
                <Card key={id} title={title} preview={preview} type={type} />
              ))}
            </SC.SectionOneMobile>
          )}
        </SC.MainWrapper>
      </SC.StyledContainer>
    </SC.StyledSection>
  );
};

export default GranichFigmaTableOfContents;
