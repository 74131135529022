import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as SC from "./style";
import GranichFigmaAuthor from "./Author";

const GranichFigmaAuthors = ({ isShowOnDesktop, isShowOnMobile }) => {
  const image = useStaticQuery(graphql`
    query granichFigmaAuthorsImage {
      vadim: file(relativePath: { eq: "granich-figma/authors/vadim.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
        }
      }
      arseny: file(relativePath: { eq: "granich-figma/authors/arseny.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
        }
      }
      vladimir: file(
        relativePath: { eq: "granich-figma/authors/vladimir.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `);

  const vadimGranich = image.vadim.childImageSharp.gatsbyImageData;
  const arsenyGoncharenko = image.arseny.childImageSharp.gatsbyImageData;
  const vladimirGranich = image.vladimir.childImageSharp.gatsbyImageData;

  const authors = [
    {
      name: "Вадим Гранич",
      authorImage: vadimGranich,
      tags: ["Соавтор", "Арт-директор"],
      aboutText: (
        <>
          <span>
            Набил себе на шее чёрную звезду, шепчет что-то про блэкстар не сходи
            с ума не жалей ни о чём...
          </span>
          <span>Пожил год в Таиланде. Кокунькрааааааб!</span>
          <span>Пожил год в Перу. Куско Урубамба Валье Саградо!</span>
          <span>
            Брендирует всё, что попадается ему под руку. Поэтому, когда Вадим
            заведёт собаку, то назовёт её Собакич. А вместо гав, она будет
            делать: Грррафф!
          </span>
        </>
      ),
    },
    {
      name: "Арсений Гончаренко",
      authorImage: arsenyGoncharenko,
      tags: ["Соавтор", "Продуктовый дизайнер"],
      aboutText: (
        <>
          <span>Дизайнил для ВДНХ, RUSSPASS и Росмолодёжи.</span>
          <span>Выиграл с командой хакатон, где за 24 часа подняли ₽200К.</span>
          <span>
            Прочитал 3 лекции про дизайн на Московском урбанистическом форуме.
          </span>
          <span>
            Бэдбой. Свалил из универа на 3 курсе, раньше, чем его отчислили.
          </span>
          <span>Черный пояс I дан по каратэ.</span>
          <span>Ведёт подкаст «Дизайн жизни».</span>
        </>
      ),
    },
    {
      name: "Владимир Гранич",
      authorImage: vladimirGranich,
      tags: ["Соавтор", "Графический дизайнер"],
      aboutText: (
        <>
          <span>Кличка «Котя».</span>
          <span>Свалил после 9 класса и начал оперативно изучать дизайн.</span>
          <span>Любитель сбивать режим сна.</span>
          <span>Вообще не любит фоткаться.</span>
          <span>Вообще не любит проигрывать.</span>
          <span>Уничтожает в Fall Guys.</span>
          <span>Выполнил челлендж в доте вместе с командой на ₽150К.</span>
        </>
      ),
    },
  ];

  return (
    <SC.StyledSection
      isShowOnDesktop={isShowOnDesktop}
      isShowOnMobile={isShowOnMobile}
    >
      <SC.StyledContainer>
        <SC.SectionTitleWrapper>
          <SC.StyledSectionTitle>Для вас трудились</SC.StyledSectionTitle>
          <SC.SectionSubTitle>
            А иногда даже дрались и кусались
          </SC.SectionSubTitle>
        </SC.SectionTitleWrapper>
        <SC.MainWrapper>
          {authors.map(({ name, authorImage, tags, aboutText }, idx) => (
            <GranichFigmaAuthor
              image={authorImage}
              name={name}
              tags={tags}
              aboutText={aboutText}
              key={idx}
            />
          ))}
        </SC.MainWrapper>
      </SC.StyledContainer>
    </SC.StyledSection>
  );
};

export default GranichFigmaAuthors;
