import styled from "styled-components";
import plusImg from "assets/images/graph-design/price-range/price-range-plus.png";
import "react-responsive-modal/styles.css";
import { Section } from "components/style";
import BuyButtonComponent from "../buttons/BuyButtonComponent";

export const StyledSection = styled(Section)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 10vw;
    margin-top: 0;
  }
`;

export const PriceRangeSection = styled.section`
  margin-bottom: 2vw;

  .container {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 575px) {
    margin-bottom: 4vw;
  }
`;

export const PriceRangeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5vw;
  grid-row-gap: 1.5vw;
  @media only screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-column-gap: 3vw;
    grid-row-gap: 3vw;
  }
`;

export const PriceCategory = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 1.5vw;
  transition: transform 0.2s ease;
  box-shadow: 0.1vw 0 0.5vw rgba(0, 0, 0, 0.15);
  @media only screen and (max-width: 575px) {
    border-radius: 5.5vw;
    box-shadow: 0.5vw 0.5vw 1.5vw rgba(0, 0, 0, 0.15);
  }
`;

export const PriceCategoryHeader = styled.div`
  padding: 1.5vw 2vw 2.5vw;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 575px) {
    padding: 6vw;
    padding-right: 0;
    padding-bottom: 8vw;
  }
`;

export const PackageImgWrapper = styled.div`
  padding: 0.5vw;
  background: white;
  border-radius: 50%;
  border: 0.1vw solid grey;
  width: 4.4vw;
  height: 4.4vw;
  min-width: 4.4vw;
  margin-right: 1vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0.6vw;

  img {
    display: block;
    width: 95%;
  }

  @media only screen and (max-width: 575px) {
    width: 12.4vw;
    height: 12.4vw;
    min-width: 12.4vw;
    margin-right: 3vw;
  }
`;

export const PackageInfo = styled.div``;

export const PriceCategoryTitle = styled.h3`
  font-size: 3.3vw;
  font-weight: 700;
  line-height: 0.55;
  @media only screen and (max-width: 575px) {
    font-size: 8vw;
  }
`;

export const PriceCategoryList = styled.div`
  li {
    position: relative;
    background: white;
    transition: transform 0.2s ease;
    line-height: 1.5;
    :first-child {
      border-top: 1px solid #dedede;
    }
    :after {
      background: ${({ disableTooltips }) =>
        disableTooltips ? "none" : `url(${plusImg})`};
      background-size: cover;
      content: "";
      position: absolute;
      top: 33%;
      right: 3.5%;
      width: 1.1vw;
      height: 1.1vw;
      border-radius: 100vw;
      color: var(--granich-light-grey);
      text-align: center;
      font-size: 1.1vw;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;
      display: none;
    }
    :hover {
      transform: ${({ disableTooltips }) =>
        disableTooltips ? "none" : "scale(1.05)"};
      box-shadow: ${({ disableTooltips }) =>
        disableTooltips ? "none" : "0 0.1vw 0.5vw rgba(0, 0, 0, 0.15)"};
      z-index: 3;
      i {
        display: block;
      }
    }
  }
  p:empty {
    display: none;
  }
  p {
    padding: 1.2vw 1vw 1.2vw 2.2vw;
    border-bottom: 1px solid #dedede;
    color: var(--granich-black);
    font-size: 1.2vw;
    font-weight: 400;
    letter-spacing: -0.01vw;
    color: var(--granich-light-grey);
    color: #d8d8d8;
    display: block;

    i {
      color: var(--granich-light-grey);
      display: none;
      letter-spacing: 0;
      position: absolute;
      width: 75%;
      bottom: 3.5vw;
      right: -31.5%;
      padding: 1vw;
      border-left: none;
      z-index: 9999;
      border-radius: 0.2vw;
      font-size: 0.9vw;
      line-height: 1.2;
      font-style: normal;
      color: var(--granich-grey);
      background: white;
      box-shadow: 0 0.2vw 0.5vw rgba(0, 0, 0, 0.2);
      :before {
        content: "";
        position: absolute;
        z-index: 9998;
        bottom: -0.45vw;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        //bottom
        border-right: 0.55vw solid transparent;
        border-left: 0.55vw solid transparent;
        border-top: 0.55vw solid white;
      }
      b {
        color: var(--granich-light-grey);
        font-weight: 400;
        :before {
          width: 0;
        }
      }
      :hover {
        display: none;
      }
    }

    b {
      font-weight: 500;
      display: block;
      letter-spacing: 0;
      i {
        color: var(--granich-grey);
        box-shadow: 0 0.2vw 0.5vw rgba(0, 0, 0, 0.2);
        background: var(--granich-black);
        :before {
          border-top: 0.55vw solid var(--granich-black);
        }
      }
      :before {
        content: "";
        position: absolute;
        width: 0.35vw;
        height: 100%;
        background: var(--granich-red);
        top: 0;
        left: 0;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    li {
      :first-child {
        border-top: 1px solid #dedede;
      }
      :hover {
        box-shadow: ${({ disableTooltips }) =>
          disableTooltips ? "none" : "0 0.1vw 0.5vw rgba(0, 0, 0, 0.15)"};
      }
      :after {
        top: 22%;
        right: 3.5%;
        width: 3.5vw;
        height: 3.5vw;
        font-size: 3vw;
      }
    }
    p {
      padding: 4vw 5vw 4vw 6vw;
      border-bottom: 1px solid #dedede;
      font-size: 5vw;
      letter-spacing: -0.08vw;
      i {
        width: 80%;
        bottom: 9vw;
        right: 0%;
        padding: 2.5vw;
        box-shadow: 0 2vw 2vw rgba(0, 0, 0, 0.2);
        border-radius: 0.2vw;
        font-size: 3.1vw;
        letter-spacing: -0.01vw;
        :before {
          bottom: -1.2vw;
          left: auto;
          right: 0;
          transform: translateX(-50%);
          //bottom
          border-right: 1.5vw solid transparent;
          border-left: 1.5vw solid transparent;
          border-top: 1.5vw solid white;
        }
      }

      b {
        letter-spacing: -0.08vw;
        :before {
          width: 1vw;
          height: 100%;
        }
        i {
          box-shadow: 0 2vw 2vw rgba(0, 0, 0, 0.2);
          :before {
            border-top: 1.5vw solid var(--granich-black);
          }
        }
      }
    }
  }
`;

export const PriceCategoryFooter = styled.footer`
  background: white;
  padding: 2vw 2.4vw;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 1.5vw 1.5vw;
  ${({ $isLast }) =>
    $isLast &&
    `
    background: var(--granich-red);
  `}
  @media only screen and (max-width: 575px) {
    padding: 5vw 9vw;
    border-radius: 0 0 5.5vw 5.5vw;
  }
`;

export const FooterInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    margin-bottom: 2vw;
  }
`;

export const Price = styled.div`
  margin-right: 1.5vw;
  border-right: 0.2vw solid var(--granich-black);
  padding-right: 1.6vw;
  font-size: 4.5vw;
  font-weight: 700;
  color: var(--granich-black);

  ${({ $isLast }) =>
    $isLast &&
    `
  border-right: 0.2vw solid white;
  color: white;
`}
  @media only screen and (max-width: 575px) {
    font-size: 14.5vw;
    border-right: none;
    border-bottom: 0.8vw solid var(--granich-black);
    padding-bottom: 2vw;
    padding-right: 0;
    width: 100%;
    text-align: center;

    ${({ $isLast }) =>
      $isLast &&
      `
    border-bottom: 0.2vw solid white;
  `}
  }
`;

export const EternalAccess = styled.div`
  font-size: 1.4vw;
  font-style: italic;
  font-weight: bold;
  font-family: EB Garamond;
  line-height: 1;
  display: flex;
  flex-direction: column;
  svg {
    width: 2vw;
    margin-bottom: 0.5vw;
  }

  ${({ $isLast }) =>
    $isLast &&
    `
    color: white;
    svg {
      fill: white;
    }
`}

  @media only screen and (max-width: 575px) {
    flex-direction: row;
    font-size: 7.4vw;
    align-items: center;
    margin-top: 4vw;
    margin-bottom: 5vw;

    svg {
      width: 6vw;
      min-width: 6vw;
      margin-bottom: 0;
      margin-right: 2vw;
    }
  }
`;

export const StyledBuyButtonComponent = styled(BuyButtonComponent)`
  font-size: 1.43vw;
  font-weight: 500;
  justify-content: center;
  padding: 0.5vw;
  width: 100%;

  svg {
    width: 1.9vw;
    :first-child {
      width: 1.6vw;
    }
  }

  :hover {
    font-size: 1.43vw;
    font-weight: 500;
  }

  @media only screen and (max-width: 575px) {
    font-size: 4.8vw;
    padding: 4.4vw;

    svg {
      width: 5.25vw;
      height: 5.2vw;
      :first-child {
        width: 5.35vw;
        height: 5.3vw;
      }
    }

    :hover {
      font-size: 4.8vw;
    }
  }
`;

export const PriceCategorySubtext = styled.div`
  text-align: center;
  font-size: 0.8vw;
  font-weight: 500;
  letter-spacing: 0;
  @media only screen and (max-width: 575px) {
    font-size: 2vw;
  }
`;

export const PriceCategoryPackage = styled.div`
  text-transform: uppercase;
  font-size: 1.24vw;
  font-weight: 600;
  font-family: Inter;
  margin-bottom: 0.6vw;
  color: var(--granich-red);
  @media only screen and (max-width: 575px) {
    font-size: 3.5vw;
    letter-spacing: 0.5vw;
    margin-bottom: 2vw;
  }
`;

export const PriceCategoryBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  margin-top: auto;
`;
