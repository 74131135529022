import React from "react";
import * as SC from "./style";

const Review = ({ reviewer, image, text }) => (
  <SC.ReviewCard>
    <SC.ReviewerImage
      alt={`reviewer-${reviewer}`}
      image={image}
      style={{ borderRadius: "50%" }}
    />
    <SC.ReviewerName>{reviewer}</SC.ReviewerName>
    <SC.ReviewerText>{text}</SC.ReviewerText>
  </SC.ReviewCard>
);

export default Review;
