import styled from "styled-components";
import { Container, SectionTitle, Section } from "components/style";

export const StyledSectiom = styled(Section)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 10vw;
    display: none;
  }
`;

export const StyledContainer = styled(Container)``;

export const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 1.4vw;
  font-size: 4.5vw;
`;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2vw;
`;

export const FactCard = styled.div`
  height: 25.5vw;
  border-radius: 1.3vw;
  overflow: hidden;
  position: relative;
  background: white;
  padding: 1.5vw 2.6vw 2vw 2.4vw;
  line-height: 1.4;

  span {
    display: block;
    font-size: 1.24vw;
    margin-bottom: 1vw;
  }
`;

export const EmojisWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8vw;
  margin-left: -0.3vw;

  img {
    width: 4.5vw;
    min-width: 4.5vw;

    :nth-child(2) {
      margin-left: -1.3vw;
      width: 4.4vw;
      min-width: 4.4vw;
    }
    :nth-child(3) {
      margin-left: -1.5vw;
    }
  }
`;

export const StyledFistImg = styled.img`
  position: absolute;
  bottom: 2.5vw;
  left: 2vw;
  width: 5.4vw;
  transform: rotate(338deg);
`;

export const StyledTwoFingersImg = styled.img`
  position: absolute;
  bottom: 2.9vw;
  left: 8.3vw;
  width: 6.5vw;
  transform: rotate(305deg);
`;

export const StyledHandImg = styled.img`
  position: absolute;
  bottom: 4.5vw;
  left: 16.5vw;
  width: 6vw;
  transform: rotate(22deg);
`;

export const StyledHundredImg = styled.img`
  position: absolute;
  bottom: 2vw;
  left: 15.2vw;
  width: 5vw;
  transform: rotate(10deg);
`;
