import React from "react";
import * as SC from "./style";

const FigmaQAndAHeader = () => (
  <SC.Header>
    <SC.StyledFigmaLogo />
    <SC.HeaderTitle>
      <span>Частые</span> вопросы
    </SC.HeaderTitle>
  </SC.Header>
);

export default FigmaQAndAHeader;
