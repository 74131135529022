import React, { useState, useRef } from "react";
import CloseIcon from "assets/svgs/granich-figma/close-icon.svg";
import useClickOutside from "hooks/useClickOutside";

import * as SC from "./style";

const GranichFigmaAuthor = ({ image, tags, name, aboutText }) => {
  const [isAdditionalInfoOpened, setIsAdditionalInfoOpened] = useState(false);
  const toggleAdditionalInfoOpened = () =>
    setIsAdditionalInfoOpened(!isAdditionalInfoOpened);

  const ref = useRef();
  useClickOutside(ref, () => setIsAdditionalInfoOpened(false));

  return (
    <SC.AuthorCard ref={ref}>
      <SC.AuthorOverflow>
        <SC.MagicWandIconButton onClick={toggleAdditionalInfoOpened}>
          <SC.StyledMagicWandIcon />
        </SC.MagicWandIconButton>
        <SC.AuthorImage image={image} alt={name} />
        <SC.AuthorInfo>
          <SC.AuthorTags>
            {tags.map((tag, idx) => (
              <SC.AuthorTag key={idx}>{tag}</SC.AuthorTag>
            ))}
          </SC.AuthorTags>
          <SC.AuthorName>{name}</SC.AuthorName>
        </SC.AuthorInfo>
      </SC.AuthorOverflow>
      <SC.StyledCSSTransition
        in={isAdditionalInfoOpened}
        timeout={300}
        classNames="author-transition"
        unmountOnExit
      >
        <SC.AdditionalInfoWrapper>
          <SC.AdditionalInfoCloseButton onClick={toggleAdditionalInfoOpened}>
            <CloseIcon />
            <span>Закрыть</span>
          </SC.AdditionalInfoCloseButton>
          <SC.AdditionalInfoTitle>
            Интересные факты о соавторе
          </SC.AdditionalInfoTitle>
          <SC.AdditionalInfoText>{aboutText}</SC.AdditionalInfoText>
        </SC.AdditionalInfoWrapper>
      </SC.StyledCSSTransition>
    </SC.AuthorCard>
  );
};

export default GranichFigmaAuthor;
