import React from "react";

import * as SC from "./style";

const Chat = ({ bubble1, bubble2 }) => (
  <SC.ChatBubblesWrapper>
    <SC.ChatBubbleLeft>{bubble1}</SC.ChatBubbleLeft>
    <SC.ChatBubbleRight>{bubble2}</SC.ChatBubbleRight>
  </SC.ChatBubblesWrapper>
);

export default Chat;
