/* eslint-disable */
const React = require("react");

const PRICE_RANGE = {
  presentation: {
    title: "Презентация в Фигме",
  },
  lessons: {
    title: "9 видео-уроков",
  },
  portfolio: {
    title: "Макет в портфолио",
  },
  access: {
    title: "Доступ в чат Школы по Фигме",
  },
  homework: {
    title: "Домашнее задание",
  },
  review: {
    title: "Индивидуальный разбор ДЗ",
  },
};
const priceRange = [
  {
    pricesTitle: "Без разбора ДЗ",
    pricesImg: "/images/granich-figma/prices/price2.jpg",
    priceConditions: [
      {
        text: PRICE_RANGE.presentation.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.lessons.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.access.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.homework.title,
        isActive: false,
      },
      {
        text: PRICE_RANGE.review.title,
        isActive: false,
      },
    ],
    price: 4900,
    getcourseInputId: "5299259",
  },
  {
    pricesTitle: "С разбором ДЗ",
    pricesImg: "/images/granich-figma/prices/price1.jpg",
    priceConditions: [
      {
        text: PRICE_RANGE.presentation.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.lessons.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.access.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.homework.title,
        isActive: true,
      },
      {
        text: PRICE_RANGE.review.title,
        isActive: true,
      },
    ],
    price: 5900,
    getcourseInputId: "5299268",
  },
];

/* eslint-disable */
module.exports = {
  figmaQandA: [
    {
      tagName: "Потоки",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>Когда будет ближайший поток?</b>
          Вы можете начать учиться в любой момент. На наших мастер-классах нет
          потоков. После оплаты вам открывается доступ к учебным материалам
          и вы сразу можете приступать к обучению.
        </p>,
      ],
    },
    {
      tagName: "Длительность",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>Сколько будет длится обучение?</b>
          Длительность обучения зависит от вашего личного темпа и того
          количества времени, которое вы будете выделять на своё образование.
        </p>,
      ],
    },
    {
      tagName: "График",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>Какой график обучения и получится ли совмещать его с работой?</b>
          Вы можете учиться в любое удобное для вас время. График
          вы устанавливаете самостоятельно. Доступ к мастер-классу остаётся
          у вас навсегда.
        </p>,
      ],
    },
    {
      tagName: "Новичкам",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>Смогу ли я осилить мастер-класс без опыта в дизайне?</b>
          Да! МК рассчитан, как на новичков совсем без опыта, так и тех у кого
          уже есть опыт работы в программах для дизайна.
        </p>,
      ],
    },
    {
      tagName: "Обратная связь",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>А будет ли какая-то обратная связь на мастер-классе?</b>
          На тарифе «С заданием» у нас есть обратная связь от кураторов
          по твоему домашнему заданию. Мы проверим, насколько хорошо ты усвоил
          материал и выполнил своё ДЗ, а так же дадим рекомендации
          по дальнейшему развитию.
        </p>,
      ],
    },
    {
      tagName: "Оплата Figma",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>
            Говорят, что Figma больше не работает в России: как мне тогда
            проходить МК?
          </b>
          Бесплатная версия Фигмы по-прежнему доступна в России. Все ограничения
          касаются только возможности оплаты платной подписки с российских карт
          и перехода с плана Professional на Organization. Для прохождения
          обучения вам будет достаточно бесплатной, доступной версии программы.
        </p>,
      ],
    },
    {
      tagName: "Рассрочка",
      tagType: true,
      isWrap: true,
      firstColumn: [
        <p>
          <b>Можно ли оформить оплату МК в рассрочку?</b>
          Да, у нас доступна программа рассрочки от Тинькофф.
        </p>,
      ],
    },
  ],
  priceRange,
};
