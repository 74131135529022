import React, { useState } from "react";
import {
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import * as SC from "./QandAItem";
import ContentComponent from "./ContentComponent";

function QandAItemMobile({ data }) {
  const [currentlyOpenIndexes, setCurrentlyOpenIndexes] = useState([0]);
  return (
    <SC.AccordionWrapperMobile
      preExpanded={currentlyOpenIndexes}
      allowMultipleExpanded
      allowZeroExpanded
      onChange={uid => setCurrentlyOpenIndexes(uid)}
    >
      {data.map(
        ({ tagName, tagType, isWrap, firstColumn, secondColumn }, idx) => {
          if (!isWrap) {
            return (
              <ContentComponent
                isWrap={isWrap}
                secondColumn={secondColumn}
                firstColumn={firstColumn}
                key={idx}
              />
            );
          }
          return (
            <SC.AccordionItemWrapper uuid={idx} key={idx}>
              <SC.AccordionHeading>
                <AccordionItemButton className="accordion-answers-button">
                  <SC.LessonAndLineWrapper>
                    <SC.TagColumn>
                      <SC.Tag type={tagType ? 1 : 0}>{tagName}</SC.Tag>
                    </SC.TagColumn>
                    <SC.ItemLineMobile
                      $isOpen={currentlyOpenIndexes.includes(idx)}
                    />
                  </SC.LessonAndLineWrapper>
                </AccordionItemButton>
              </SC.AccordionHeading>
              <AccordionItemPanel className="accordion__panel accordion__panel_answers">
                <ContentComponent
                  isWrap={isWrap}
                  secondColumn={secondColumn}
                  firstColumn={firstColumn}
                />
              </AccordionItemPanel>
            </SC.AccordionItemWrapper>
          );
        }
      )}
    </SC.AccordionWrapperMobile>
  );
}

export default QandAItemMobile;
