import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as SC from "./style";

const GranichFigmaLotsOfSlides = () => {
  const image = useStaticQuery(graphql`
    query granichFigmaLotsOfSlidesImage {
      placeholderImage: file(
        relativePath: { eq: "granich-figma/lots-of-slides/lots-of-slides.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const imageData = image.placeholderImage.childImageSharp.fluid;

  return (
    <SC.StyledSection>
      <SC.StyledContainer>
        <SC.StyledWrapper>
          <SC.StyledSectionTitle>
            Там реально мноооого слайдов
          </SC.StyledSectionTitle>
          <SC.StyledSectionSubtitle>
            Уроки разложены по полочкам. Идут по увеличению сложности. Шаг
            за шагом.
          </SC.StyledSectionSubtitle>
          <SC.SlidesImageWrapper>
            <SC.SlidesImage fluid={imageData} />
          </SC.SlidesImageWrapper>
        </SC.StyledWrapper>
      </SC.StyledContainer>
    </SC.StyledSection>
  );
};

export default GranichFigmaLotsOfSlides;
