import React from "react";

import * as SC from "./style";

const GranichFigmaFacts = () => (
  <SC.StyledSectiom>
    <SC.StyledContainer>
      <SC.StyledSectionTitle>Интересные факты о МК</SC.StyledSectionTitle>
      <SC.MainWrapper>
        <SC.FactCard>
          <SC.EmojisWrapper>
            <img src="/images/emojis/_crazy.png" loading="lazy" alt="crazy" />
            <img src="/images/emojis/_france.png" loading="lazy" alt="france" />
            <img
              src="/images/emojis/_baguette.png"
              loading="lazy"
              alt="baguette"
            />
          </SC.EmojisWrapper>
          <span>
            При записи видеолекций, Гранич немного чокнулся и у него появился
            странный французский акцент.
          </span>
          <span>Мы все очень переживаем.</span>
          <span>
            Пожалуйста, купите этот МК, мы оплатим Граничу уроки французского.
          </span>
        </SC.FactCard>
        <SC.FactCard>
          <SC.EmojisWrapper>
            <img src="/images/emojis/_dice.png" loading="lazy" alt="dice" />
          </SC.EmojisWrapper>
          <span>
            Супер-мега-фича мастер-класса — мы научимся играть в Фигме
            в камень–ножницы–бумага и бросать кубик!
          </span>
          <SC.StyledFistImg
            src="/images/emojis/_fist.png"
            loading="lazy"
            alt="fist"
          />
          <SC.StyledTwoFingersImg
            src="/images/emojis/_two_fingers.png"
            loading="lazy"
            alt="two_fingers"
          />
          <SC.StyledHandImg
            src="/images/emojis/_hand.png"
            loading="lazy"
            alt="hand"
          />
        </SC.FactCard>
        <SC.FactCard>
          <SC.EmojisWrapper>
            <img
              src="/images/emojis/_boy_glasses.png"
              loading="lazy"
              alt="boy_glasses"
            />
          </SC.EmojisWrapper>
          <span>
            А ещё в наш мастер-класс вшито домашнее задание в виде
            тренировочного сайта, который вы будете делать шаг за шагом, из
            урока в урок, попутно изучая важные функции Фигмы.
          </span>
          <SC.StyledHundredImg
            src="/images/emojis/_hundred.png"
            loading="lazy"
            alt="hundred"
          />
        </SC.FactCard>
      </SC.MainWrapper>
    </SC.StyledContainer>
  </SC.StyledSectiom>
);

export default GranichFigmaFacts;
