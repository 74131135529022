import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import InstrumentsIllustration from "assets/svgs/granich-figma/table-of-contents/instruments.svg";
import GridsIllustration from "assets/svgs/granich-figma/table-of-contents/grids.svg";
import TextIllustration from "assets/svgs/granich-figma/table-of-contents/text.svg";
import AutoLayoutsIllustration from "assets/svgs/granich-figma/table-of-contents/auto-layouts.svg";
import ComponentsIllustration from "assets/svgs/granich-figma/table-of-contents/components.svg";
import ButtonsIllustration from "assets/svgs/granich-figma/table-of-contents/buttons.svg";
import TypesOfComponentsIllustration from "assets/svgs/granich-figma/table-of-contents/types-of-components.svg";
import StyleIllustration from "assets/svgs/granich-figma/table-of-contents/style-illustration.svg";
import PrototypingIllustration from "assets/svgs/granich-figma/table-of-contents/prototyping.svg";
import AppsIllustration from "assets/svgs/granich-figma/table-of-contents/apps.svg";
import DevelopmentIllustration from "assets/svgs/granich-figma/table-of-contents/development.svg";
import Tooltip from "assets/svgs/granich-figma/table-of-contents/tooltip.svg";
import { Container, NewSectionTitle, Section } from "components/style";

export const StyledSection = styled(Section)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 0;
  }
`;

export const StyledContainer = styled(Container)``;

export const StyledSectionTitle = styled(NewSectionTitle)`
  margin-bottom: 1.6vw;
  font-size: 4.76vw;

  @media only screen and (max-width: 575px) {
    font-size: 8.1vw;
    letter-spacing: 0;
    max-width: 80%;
    margin-bottom: 8vw;
  }
`;

export const MainWrapper = styled.div``;

export const SectionOneDesktop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5vw;
`;

export const SectionOneMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5vw;
`;

export const SectionTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5vw;
`;

export const CardWrapper = styled.div`
  background: white;
  border-radius: 1.3vw;
  padding: 2vw;
  border: 0.1vw solid rgba(150, 150, 150, 1);
  overflow: hidden;
  max-height: 20.8vw;

  @media only screen and (max-width: 575px) {
    height: 49vw;
    max-height: 49vw;
    border-width: 0.15vw;
    border-radius: 3vw;
    padding: 4.5vw;
  }
`;

export const CardTitle = styled.div`
  font-size: 1.65vw;
  margin-bottom: 2vw;
  line-height: 1.1;
  max-width: 92%;
  position: relative;
  top: -0.2vw;

  @media only screen and (max-width: 575px) {
    font-size: 3.75vw;
    margin-top: -1vw;
    margin-bottom: 4vw;
  }
`;

export const CardContent = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

export const StyledInstrumentsIllustration = styled(InstrumentsIllustration)`
  position: relative;
  bottom: -2vw;
  transform: scale(1.05);

  @media only screen and (max-width: 575px) {
    bottom: -5vw;
  }
`;

export const StyledGridsIllustration = styled(GridsIllustration)`
  position: absolute;
  box-shadow: 0.1vw 0 0.5vw rgba(0, 0, 0, 0.15);
  border-radius: 0.2vw;
  bottom: 3vw;
  transform: scale(1.05);

  @media only screen and (max-width: 575px) {
    border-radius: 1.5vw;
    bottom: -1vw;
  }
`;

export const StyledAutoLayoutsIllustration = styled(AutoLayoutsIllustration)`
  margin: 0 auto;
  width: 75%;
  position: relative;
  left: 50%;
  top: 2vw;
  transform: translateX(-50%);

  @media only screen and (max-width: 575px) {
    top: 6.5vw;
  }
`;

export const StyledTextIllustration = styled(TextIllustration)`
  transform: scale(1.1);
  @media only screen and (max-width: 575px) {
    position: relative;
    transform: scale(1.1);
    top: 1.5vw;
  }
`;

export const StyledComponentsIllustration = styled(ComponentsIllustration)`
  position: relative;
  top: -0.5vw;
  max-width: 94%;
`;

export const StyledGraphicsImage = styled(GatsbyImage)`
  position: relative;
  top: -0.7vw;
`;

export const StyledEffectsImage = styled(GatsbyImage)`
  position: relative;
  top: 2vw;
  transform: scale(1.05);

  @media only screen and (max-width: 575px) {
    top: 5vw;
  }
`;

export const StyledButtonsIllustration = styled(ButtonsIllustration)`
  position: relative;
  top: 0.5vw;

  @media only screen and (max-width: 575px) {
    top: 2.5vw;
  }
`;

export const StyledTypesOfComponentsIllustration = styled(
  TypesOfComponentsIllustration
)`
  position: relative;
  top: -0.7vw;
  max-width: 110%;
  transform: scale(1.1);

  @media only screen and (max-width: 575px) {
    top: -0.8vw;
  }
`;

export const StyledPrototypingIllustration = styled(PrototypingIllustration)``;

export const TestingAppsIllustrationWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: -1.1vw;

  svg {
    width: 5.5vw;
    min-width: 5.5vw;
    margin-right: 0.5vw;

    :last-child {
      width: 8vw;
      min-width: 8vw;
      margin-right: 0vw;
    }
  }

  @media only screen and (max-width: 575px) {
    svg {
      width: 13vw;
      margin-right: 1.5vw;

      :last-child {
        width: 19.1vw;
      }
    }
  }
`;

export const FigmaWrapper = styled.div``;

export const StyledAppsIllustration = styled(AppsIllustration)``;

export const StyledDevelopmentIllustration = styled(DevelopmentIllustration)`
  width: 100%;
  height: auto;
`;

export const StyledStylesImage = styled(GatsbyImage)`
  width: 125%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -1vw;

  div {
    max-width: 100% !important;
  }
`;

export const StyledIPhone = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  div {
    max-width: 100% !important;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5.6vw;

  @media only screen and (max-width: 575px) {
    height: 13.5vw;
  }
`;

export const TooltopWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const StyledStyleIllustration = styled(StyleIllustration)`
  position: relative;
  top: 0.5vw;
  transform: scale(1.15);

  @media only screen and (max-width: 575px) {
    top: 2.5vw;
  }
`;
