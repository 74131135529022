import React, { useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Cross from "assets/svgs/cross.svg";
import CopyMailToClipboardComponent from "components/common/copy-to-clipboard";
import ShakeForm from "components/common/shake-form";
import useGetCourseFormEncrypt from "hooks/useGetCourseFormEncrypt";
import * as SC from "./style";

const PriceRangeModal = ({
  chosenPrice,
  closeModal,
  formId,
  formPostUrl,
  priceRange,
}) => {
  const formEl = useRef(null);
  const submitForm = () => {
    formEl.current.submit();
  };

  const [shakeTrigger, setShakeTrigger] = useState(3);
  function isInitialValid(props) {
    if (!props.validationSchema) return true;
    return props.validationSchema.isValidSync(props.initialValues);
  }

  const { getCourseTags, getCourseButtonOnClick } = useGetCourseFormEncrypt();

  return (
    <>
      <ShakeForm pose={["shake"]} poseKey={shakeTrigger}>
        <Formik
          isInitialValid={isInitialValid}
          initialValues={{
            formParams: {
              offer_id: `${chosenPrice}`,
              email: "",
            },
          }}
          onSubmit={submitForm}
          validationSchema={Yup.object().shape({
            formParams: Yup.object().shape({
              offer_id: Yup.string().required("Выберите один из пакетов"),
              email: Yup.string()
                .email("Это не Электропочта")
                .required("Сперва впишите Электропочту"),
            }),
          })}
        >
          {props => {
            const {
              values,
              errors,
              isSubmitting,
              handleChange,
              handleSubmit,
              isValid,
              touched,
              handleBlur,
              setFieldValue,
            } = props;
            return (
              <SC.Form
                ref={formEl}
                onSubmit={e => {
                  if (isValid) {
                    handleSubmit(e);
                  } else {
                    handleSubmit(e);
                    setShakeTrigger(shakeTrigger + 1);
                  }
                }}
                id={formId}
                action={formPostUrl}
                method="post"
                data-open-new-window="0"
              >
                <SC.FormWrapper>
                  <SC.FormCross onClick={closeModal}>
                    <Cross />
                  </SC.FormCross>

                  <SC.FormContainer>
                    <SC.FormTitle>
                      Выберите <br />
                      нужный пакет
                    </SC.FormTitle>
                    <input type="hidden" name="formParams[setted_offer_id]" />
                    <input
                      id="input-formPosition9651352"
                      name="formParams[need_offer]"
                      type="hidden"
                      value=""
                    />
                    <br />
                    {priceRange.map(
                      ({ price, getcourseInputId, pricesTitle }) => (
                        <React.Fragment key={getcourseInputId}>
                          <SC.FormCheckbox
                            data-price-value={price}
                            data-old-price-value={price}
                            data-price-currency="руб."
                            data-qty-value="1"
                            type="radio"
                            name="formParams[offer_id]"
                            value={getcourseInputId}
                            checked={
                              values.formParams.offer_id === String(price)
                            }
                            onChange={() => {
                              setFieldValue(
                                "formParams[offer_id]",
                                String(price)
                              );
                            }}
                            id={price}
                            className="course-form-checkbox"
                            errorStyle={
                              errors.formParams &&
                              errors.formParams.offer_id &&
                              touched.formParams &&
                              touched.formParams.offer_id
                                ? 1
                                : 0
                            }
                          />
                          <SC.FormCheckboxLabel
                            line
                            className={`course-form-label ${
                              errors.formParams &&
                              errors.formParams.offer_id &&
                              touched.formParams &&
                              touched.formParams.offer_id &&
                              "course-form-label_error"
                            }`}
                            htmlFor={price}
                          >
                            <SC.FormLabelText>{pricesTitle}</SC.FormLabelText>
                            <SC.FormLabelPrice>{price} ₽</SC.FormLabelPrice>
                          </SC.FormCheckboxLabel>
                        </React.Fragment>
                      )
                    )}
                    <SC.FormInput
                      type="email"
                      maxLength="60"
                      placeholder="Электропочта"
                      name="formParams[email]"
                      value={values.formParams.email}
                      onChange={handleChange}
                      id="formParams[email]"
                      onBlur={handleBlur}
                      errorStyle={
                        errors.formParams &&
                        errors.formParams.email &&
                        touched.formParams &&
                        touched.formParams.email
                          ? 1
                          : 0
                      }
                    />

                    <SC.FormInputLabel htmlFor="formParams[email]">
                      чтобы выслать ссылку для входа
                    </SC.FormInputLabel>
                    <SC.FormInputLabelMobile htmlFor="formParams[email]">
                      чтобы выслать ссылку для входа
                    </SC.FormInputLabelMobile>
                    <br />
                    {errors.formParams &&
                      errors.formParams.email &&
                      touched.formParams &&
                      touched.formParams.email && (
                        <SC.ErrorMessage>
                          {errors.formParams.email}
                        </SC.ErrorMessage>
                      )}
                    <SC.FormPolitikaLabel>
                      Нажимая на кнопку в этой форме, я принимаю условия{" "}
                      <a target="_blank" href="/privacy">
                        {" "}
                        политики конфиденциальности
                      </a>{" "}
                      <SC.CustomBr />и{" "}
                      <a target="_blank" href="/public-offer">
                        {" "}
                        учебного договора
                      </a>
                    </SC.FormPolitikaLabel>
                  </SC.FormContainer>
                </SC.FormWrapper>
                <SC.FormFooterWrapper>
                  <SC.FormFooterContainer>
                    <SC.FormButtonBlock>
                      <SC.FormButton
                        disabled={isSubmitting}
                        type="submit"
                        id="button1106661"
                        onClick={getCourseButtonOnClick}
                      >
                        Купить
                      </SC.FormButton>
                    </SC.FormButtonBlock>

                    <SC.FormSubText>
                      Вы всегда можете докупить более дорогой пакет. В нем уже
                      будет учтена сумма, которую вы оплатили за более дешевый
                      пакет. Для этого напишите координатору школы на
                      <CopyMailToClipboardComponent>
                        <SC.FormSubTextEmail>
                          hello@granich.design
                        </SC.FormSubTextEmail>
                      </CopyMailToClipboardComponent>
                    </SC.FormSubText>
                  </SC.FormFooterContainer>
                </SC.FormFooterWrapper>
                {getCourseTags}
              </SC.Form>
            );
          }}
        </Formik>
      </ShakeForm>
    </>
  );
};

export default PriceRangeModal;
