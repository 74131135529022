import styled from "styled-components";
import CartIcon from "assets/svgs/granich-figma/cart.svg";
import GranichFigmaLogoFilled from "assets/svgs/granich-figma/figma-logo_filled.svg";

export const StyledButton = styled.button`
  background: var(--granich-red-vertical-gradient);
  border-radius: 3vw;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 2.4vw;
  box-shadow: none;
  color: white;
  padding: 1.2vw 1.65vw;

  :hover {
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 2.4vw;
    background: var(--granich-red_button-hover);
  }

  @media only screen and (max-width: 575px) {
    font-size: 4.8vw;
    padding: 4.4vw 5.8vw;
    border-radius: 7vw;

    :hover {
      font-size: 4.8vw;
    }
  }
`;

export const StyledGranichFigmaLogoFilled = styled(GranichFigmaLogoFilled)`
  width: 2.1vw;
  height: 2vw;
  margin-right: 0.35vw;

  @media only screen and (max-width: 575px) {
    width: 5.35vw;
    height: 5.3vw;
    margin-right: 1vw;
  }
`;

export const StyledCartIcon = styled(CartIcon)`
  width: 2.3vw;
  height: 2.2vw;
  margin-right: 0.4vw;

  @media only screen and (max-width: 575px) {
    width: 5.25vw;
    height: 5.2vw;
    margin-right: 1vw;
  }
`;
