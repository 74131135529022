import React from "react";
import NoWaterIcon from "assets/svgs/granich-figma/features/no-water.svg";
import TheoryIcon from "assets/svgs/granich-figma/features/theory.svg";
import EternityIcon from "assets/svgs/granich-figma/features/eternity.svg";
import PracticeIcon from "assets/svgs/granich-figma/features/practice.svg";

import * as SC from "./style";

const GranichFigmaFeatures = () => {
  const features = [
    {
      icon: null,
      tag: null,
      text: null,
    },
    {
      icon: <NoWaterIcon />,
      tag: "Чистый смысл",
      text: "Только нужный материал без воды",
    },
    {
      icon: <TheoryIcon />,
      tag: "База знаний",
      text: "Вся теория в одном месте",
    },
    {
      icon: <EternityIcon />,
      tag: "Учись, когда удобно",
      text: "Бессрочный доступ к учебным материалам",
    },
    {
      icon: <PracticeIcon />,
      tag: "Акцент на практике",
      text: "Изучаем Фигму, делаем сайт по ходу мастер-класса",
    },
  ];

  return (
    <SC.StyledSection>
      <SC.StyledContainer>
        <SC.StyledWrapper>
          <SC.LightShadow />
          {features.map(({ icon, tag, text }, idx) => (
            <SC.Feature key={idx}>
              <SC.FeatureHeader>
                {icon}
                {tag && <SC.FeatureTag>{tag}</SC.FeatureTag>}
              </SC.FeatureHeader>
              <SC.FeatureMain>
                <SC.FeatureLine
                  $isLast={features.length === idx + 1 || idx === 3}
                />
                {text && <SC.FeatureText>{text}</SC.FeatureText>}
              </SC.FeatureMain>
            </SC.Feature>
          ))}
          <SC.LightShadow bottom />
        </SC.StyledWrapper>
      </SC.StyledContainer>
    </SC.StyledSection>
  );
};

export default GranichFigmaFeatures;
