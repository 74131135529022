import styled from "styled-components";
import { Container, Section, Wrapper } from "components/style";

export const StyledSection = styled(Section)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const StyledContainer = styled(Container)``;

export const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 575px) {
    padding-bottom: 20vw;
  }
`;

export const LightShadow = styled.div`
  position: absolute;
  ${({ bottom }) => (bottom ? "bottom: 0;" : "top: 0;")}
  left: 0;
  z-index: 2;
  width: 100%;
  background: linear-gradient(
    ${({ bottom }) => (bottom ? "0deg" : "180deg")},
    rgba(242, 242, 242, 1) 30%,
    rgba(242, 242, 242, 0) 100%
  );
  height: ${({ bottom }) => (bottom ? "15vw" : "15vw")};

  @media only screen and (max-width: 575px) {
    height: ${({ bottom }) => (bottom ? "70vw" : "60vw")};
  }
`;

export const Feature = styled.div``;

export const FeatureHeader = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin: 1.1vw 1.8vw 1.1vw -0.75vw;
    width: 4vw;
    min-width: 4vw;
    height: 4vw;
  }

  @media only screen and (max-width: 575px) {
    margin-bottom: 8vw;

    svg {
      width: 13vw;
      min-width: 13vw;
      margin-right: 2.5vw;
      position: relative;
      margin-left: -3vw;
      top: 4vw;
      height: 12vw;
    }
  }
`;

export const FeatureTag = styled.div`
  border: 0.15vw solid var(--granich-red);
  border-radius: 10vw;
  color: var(--granich-red);
  font-size: 1.4vw;
  padding: 1vw;
  font-style: italic;
  font-weight: bold;
  font-family: EB Garamond;
  position: relative;
  line-height: 0.6;

  @media only screen and (max-width: 575px) {
    font-size: 5.2vw;
    padding: 2.2vw 3.5vw 2.5vw;
    top: 4.5vw;
    border-width: 0.5vw;
  }
`;

export const FeatureText = styled.div`
  font-size: 3.27vw;
  font-weight: 600;
  position: relative;
  z-index: 3;
  max-width: 90%;
  margin-top: -0.25vw;
  @media only screen and (max-width: 575px) {
    font-size: 8vw;
    line-height: 1;
    font-weight: 700;
  }
`;

export const FeatureMain = styled.div`
  display: flex;
  align-items: start;
`;

export const FeatureLine = styled.div`
  width: 0.15vw;
  min-width: 0.15vw;
  background: var(--granich-black);
  height: ${({ $isLast }) => ($isLast ? "10vw" : "7vw")};
  margin-right: 4.5vw;
  margin-left: 1.17vw;
  position: relative;

  @media only screen and (max-width: 575px) {
    height: ${({ $isLast }) => ($isLast ? "51vw" : "39vw")};
    width: 0.6vw;
    min-width: 0.6vw;
    margin-left: 3.5vw;
    margin-right: 10vw;
  }
`;
