import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import GranichExplainsIcon from "assets/svgs/granich-figma/why-should-i-buy/granich-explains-icon.svg";
import { Container, Section } from "components/style";

export const StyledSection = styled(Section)``;

export const StyledContainer = styled(Container)``;

export const MainWrapper = styled.div`
  padding: 5vw 4vw 13.3vw;
  position: relative;

  @media only screen and (max-width: 575px) {
    padding: 31vw 0 33vw;
  }
`;

export const IrritatedCatWrapper = styled.div`
  display: flex;
  align-items: center;
  transform: rotate(-5deg);
`;

export const IrritatedCatImg = styled(GatsbyImage)`
  position: relative;
  z-index: 1;
  width: 10.8vw;
  min-width: 10.8vw;
  height: 10.8vw;

  @media only screen and (max-width: 575px) {
    width: 20vw;
    min-width: 20vw;
    height: 20vw;
  }
`;

export const IrritatedCatText = styled.div`
  background: rgba(148, 148, 148, 1);
  border-radius: 1.4vw 1.4vw 1.4vw 0.4vw;
  color: white;
  padding: 1.2vw 1.8vw;
  max-width: 33vw;
  position: relative;
  left: -0.4vw;
  top: -0.7vw;
  font-size: 1.65vw;
  line-height: 1.38;

  @media only screen and (max-width: 575px) {
    border-radius: 4.4vw 4.4vw 4.4vw 1.4vw;
    padding: 3.5vw;
    max-width: 82vw;
    width: 82vw;
    min-width: 82vw;
    left: -13vw;
    top: -19.5vw;
    font-size: 4.4vw;
  }
`;

export const GranichExplainsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 4.5vw;
  right: 5.3vw;

  @media only screen and (max-width: 575px) {
    bottom: 6.5vw;
    right: 0;
  }
`;

export const StyledGranichExplainsIcon = styled(GranichExplainsIcon)`
  width: 11vw;
  height: 11vw;

  @media only screen and (max-width: 575px) {
    width: 20vw;
    height: 20vw;
  }
`;

export const GranichExplainsText = styled.div`
  background: var(--granich-red-vertical-gradient);
  padding: 1.1vw 1.5vw;
  border-radius: 1.4vw 1.4vw 0.4vw 1.4vw;
  color: white;
  font-size: 1.65vw;
  position: relative;
  top: -2.1vw;
  right: -3.4vw;

  @media only screen and (max-width: 575px) {
    font-size: 4.4vw;
    padding: 3vw;
    border-radius: 4.4vw 4.4vw 1.4vw 4.4vw;
    top: -8.3vw;
    right: -6.7vw;
  }
`;
