import styled from "styled-components";
import FigmaLogo from "assets/svgs/granich-figma/figma-logo_filled.svg";

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
  margin-top: -1.8vw;

  @media only screen and (max-width: 575px) {
    margin-top: 0;
  }
`;

export const HeaderTitle = styled.div`
  font-size: 5.5vw;
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  color: var(--granich-black);

  span {
    color: var(--granich-red);
    font-size: 5.5vw;
    font-family: EB Garamond;
    font-style: italic;
    font-weight: 500;
  }

  @media only screen and (max-width: 575px) {
    font-size: 9vw;
    white-space: nowrap;

    span {
      font-size: 9vw;
    }
  }
`;

export const StyledFigmaLogo = styled(FigmaLogo)`
  width: 8.4vw;
  min-width: 8.3vw;
  height: 8.3vw;
  margin-right: 2.6vw;
  margin-left: 0.2vw;

  @media only screen and (max-width: 575px) {
    width: 14vw;
    min-width: 14vw;
    height: 14vw;
    margin-right: 2vw;
  }
`;
