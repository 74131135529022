import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "react-responsive-modal";
import PriceRangeModal from "components/pages/course-page/price-range/modal";
import EternityIcon from "assets/svgs/granich-figma/eternity.svg";
import { Container } from "components/style";
import * as SC from "./style";

const GranichFigmaPriceRange = ({ priceRange, formId, formPostUrl }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [chosenPrice, setChosenPrice] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <SC.StyledSection>
      <Container>
        <Modal
          center
          closeIcon={<div />}
          open={modalIsOpen}
          onClose={closeModal}
          focusTrapped={false}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <PriceRangeModal
            priceRange={priceRange}
            courseTitle="Granich Figma"
            formId={formId}
            formPostUrl={formPostUrl}
            chosenPrice={chosenPrice}
            openModal={openModal}
            closeModal={closeModal}
            modalIsOpen={modalIsOpen}
          />
        </Modal>

        <SC.PriceRangeWrapper pricesNumber={priceRange.length}>
          {priceRange.map(
            ({ pricesTitle, priceConditions, pricesImg, price }, idx) => {
              const isLast = priceRange.length === idx + 1;
              return (
                <SC.PriceCategory key={uuidv4()}>
                  <SC.PriceCategoryHeader>
                    <SC.PackageImgWrapper>
                      <img src={pricesImg} alt={pricesTitle} loading="lazy" />
                    </SC.PackageImgWrapper>
                    <SC.PackageInfo>
                      <SC.PriceCategoryPackage id="price-category-package">
                        Пакет
                      </SC.PriceCategoryPackage>
                      <SC.PriceCategoryTitle>
                        {pricesTitle}
                      </SC.PriceCategoryTitle>
                    </SC.PackageInfo>
                  </SC.PriceCategoryHeader>
                  <SC.PriceCategoryBody>
                    <SC.PriceCategoryList
                      disableTooltips
                      className="price-category-list"
                    >
                      <ul>
                        {priceConditions.map(({ text, isActive }) => (
                          <li key={uuidv4()}>
                            <p>{isActive ? <b>{text}</b> : text}</p>
                          </li>
                        ))}
                      </ul>
                    </SC.PriceCategoryList>
                    <SC.PriceCategoryFooter $isLast={isLast}>
                      <SC.FooterInfo>
                        <SC.Price $isLast={isLast}>{price} ₽</SC.Price>
                        <SC.EternalAccess $isLast={isLast}>
                          <EternityIcon />
                          Бессрочный доступ
                        </SC.EternalAccess>
                      </SC.FooterInfo>
                      <div
                        onClick={() => {
                          openModal();
                          setChosenPrice(price);
                        }}
                      >
                        <SC.StyledBuyButtonComponent>
                          Купить
                        </SC.StyledBuyButtonComponent>
                      </div>
                    </SC.PriceCategoryFooter>
                  </SC.PriceCategoryBody>
                </SC.PriceCategory>
              );
            }
          )}
        </SC.PriceRangeWrapper>
      </Container>
    </SC.StyledSection>
  );
};

export default GranichFigmaPriceRange;
